<template>
    <div style="width: 100%">
        <div class="fontS14c3">组件样式</div>
        <!-- 样式 -->
        <div class="marB20 flex marT20">
            <div class="fontS14c3">组件背景</div>
            <div class="marL20">
                <el-radio-group v-model="data.isLayout">
                    <el-radio :label="0">透明</el-radio>
                    <el-radio :label="1">颜色</el-radio>
                    <el-radio :label="2">图片</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="flex" v-if="data.isLayout == '1'">
            <div>背景颜色: </div>
            <div class="marL20">
                <el-color-picker v-model="data.style.bgColor"></el-color-picker>
            </div>
        </div>
        <div class="flex" v-if="data.isLayout == '2'">
            <div>背景图片: </div>
            <div class="marL20">
                <div class="x-x marB10">
                    <div class="selectIconBox x-fc" @click="getReplace(1)">
                        <el-image style="width: 70px; height: 70px;border: 1px solid #ccc;" :src="data.bgImg" fit="cover">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="fontS14c3">内容样式</div>
        <div class="flex marT20">
            <div>门店LOGO: </div>
            <div class="marL20">
                <el-radio-group v-model="data.logType">
                    <el-radio :label="0">圆形</el-radio>
                    <el-radio :label="1">圆角</el-radio>
                    <el-radio :label="2">不显示</el-radio>
                </el-radio-group>
            </div>
        </div>
        <!-- <div class="flex marT20" v-if="data.logType !== 2">
            <div>LOGO图片: </div>
            <div class="marL20">
                <div class="x-x marB10">
                    <div class="selectIconBox x-fc" @click="getReplace(2)">
                        <el-image style="width: 70px; height: 70px;border: 1px solid #ccc;" :src="data.loImg" fit="cover">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="flex marT20">
            <div>门店名称: </div>
            <el-color-picker class="marL20" v-model="data.style.iconColor"></el-color-picker>
            <el-input v-model="data.style.iconColor" style="width: 120px"></el-input>
            <el-button @click="restColor('iconColor')">重置</el-button>
        </div>
        <div class="flex marT20">
            <div>门店地址: </div>
            <el-color-picker class="marL20" v-model="data.style.textColor"></el-color-picker>
            <el-input v-model="data.style.textColor" style="width: 120px"></el-input>
            <el-button @click="restColor('textColor')">重置</el-button>
        </div>
        <div class="flex marT20">
            <div>门店距离: </div>
            <el-color-picker class="marL20" v-model="data.style.angleMarkColor"></el-color-picker>
            <el-input v-model="data.style.angleMarkColor" style="width: 120px"></el-input>
            <el-button @click="restColor('angleMarkColor')">重置</el-button>
        </div>
        <div class="flex marT20">
            <div>分割线: </div>
            <el-color-picker class="marL20" v-model="data.style.leftBtnColor"></el-color-picker>
            <el-input v-model="data.style.leftBtnColor" style="width: 120px"></el-input>
            <el-button @click="restColor('leftBtnColor')">重置</el-button>
        </div>
        <div class="flex marT20">
            <div>切换门店：</div>
            <div>
                <div class="flex marT20" @click="openIcons = true">
                    <span>图标：</span>
                    <div style="border: 1px solid #f4f4f4;padding: 10px">
                        <i v-show="!data.seeMoreIcon" class="el-icon-plus" style="font-size: 32px;color: #ccc;"></i>
                        <iconParkALi v-show="data.seeMoreIcon" :name="data.seeMoreIcon" size="32px" />
                    </div>
                </div>
                <div class="flex marT20">
                    <span>图标颜色：</span>
                    <!-- <div> -->
                        <el-color-picker v-model="data.style.iconColor"></el-color-picker>
                        <el-input v-model="data.style.iconColor" style="width: 100px"></el-input>
                        <el-button @click="restColor('iconColor')">重置</el-button>
                    <!-- </div> -->
                </div>
                <div class="flex marT20">
                    <span>文字颜色：</span>
                    <el-color-picker v-model="data.style.iconText"></el-color-picker>
                    <el-input v-model="data.style.iconText" style="width: 100px"></el-input>
                    <el-button @click="restColor('iconText')">重置</el-button>
                </div>
                <!-- <div class="flex marT20">
                    <span>边框颜色：</span>
                    <el-color-picker v-model="data.style.iconBor"></el-color-picker>
                    <el-input v-model="data.style.iconBor" style="width: 100px"></el-input>
                    <el-button @click="restColor('iconBor')">重置</el-button>
                </div> -->
            </div>
        </div>
        <div class="marT20">
            <!-- 上边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">上边距</span>
                <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
            </div>
            <!-- 下边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">下边距</span>
                <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
            </div>
            <!-- 左边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">左边距</span>
                <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
            </div>
            <!-- 右边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">右边距</span>
                <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
            </div>
            <!-- 圆角 -->
            <div class="x-f marB20">
                <span class="fontS14 radiuDistance">圆角</span>
                <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
            </div>
        </div>
        <!-- 图片选择弹窗 -->
        <el-dialog title="素材库" :visible.sync="openIcon" width="80%" :destroy-on-close="true">
            <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
                <el-button @click="imgUpdateCancel">取 消</el-button>
            </div>
        </el-dialog>
        <selectIcon :OpenIcon.sync="openIcons" @selectIcon="selectIcon" />
    </div>
</template>
  
<script>
import Material from '@/views/components/material/index.vue' //素材库
import selectIcon from '@/views/components/selectIcon/index.vue' //图标库

export default {
    name: 'switching',
    components: { Material, selectIcon },
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            openIcon: false,
            openIcons: false,
            accessUrlList: '',
            indexs: ''
        }
    },
    methods: {
        // //选择好的图标
        selectIcon(value) {
            console.log('---------', value)
            this.data.seeMoreIcon = value
        },
        restColor(val) {
            this.data.style[val] = '#000000'
        },
        getReplace(indexs) {
            this.indexs = indexs
            this.openIcon = true
        },
        getAccessUrlList(value) {
            console.log('选择的图片：', value)
            if (this.indexs === 1) {
                this.data.bgImg = value[0]

            } else {
                this.data.loImg = value[0]
            }
            this.templateMsg()
        },
        imgUpdateSubmit() {
            if (this.accessUrlList.length > 1) {
                return this.$message.error('图片信息只能单选')
            }
            this.openIcon = false
        },
        imgUpdateCancel() {
            // this.accessUrlList = ''
            this.openIcon = false
        },


        //更多设置区域点击事件 type区分类型 pitchVal选中的值
        getShow(type, bol) {
            this.data[type] = bol
            this.templateMsg()
        },
        templateMsg() {
            this.$emit('setTemp', this.data)
        }
    }
}
</script>
  
<style lang="scss" scoped>
.flex {
    display: flex;
    align-items: center;
}

//简单按钮样式
.simpleBtn {
    text-align: center;
    border: 1px solid #dcdee0;
    height: 32px;
    line-height: 30px;
    padding: 0 16px;

    &.active {
        background: #edf4ff;
        border: 1px solid #155bd4;
        color: #155bd4;
    }
}

//收货方式
.receiving {
    width: 140px;
}

/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #303133 !important;
}

::v-deep .el-color-picker__trigger {
    width: 70px !important;
}

::v-deep .image-slot {
    text-align: center;
    margin-top: 22px;
}

.slider {
    width: calc(100% - 106px);
}

//圆角
.radiuDistance {
    margin-right: 62px;
}

.slider {
    width: calc(100% - 106px);
}
</style>
  