<template>
  <div>
    <el-form :model="data" :rules="rules">
      <el-form-item>
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 label">应用页面</span>
          </div>
        </div>
        <div class="x-bc">
          <div class="x-f btnListBox">
            <el-checkbox-group v-model="data.checkedList">
              <el-checkbox v-for="item in data.tableList" :label="item" :key="item">{{item}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-form-item>

      <el-form-item>
        <div class="imageNavText">
          <el-card
            v-for="(item, index) in data.immageNavList"
            :key="index"
            class="product-item marB10"
          >
            <div class="x-bc">
              <!-- 左边图片 -->
              <div
                class="imgBox marR10"
                @click="getImgLibrary(index)"
                v-show="data.textFormworkNav == 'imageFormwork'"
              >
                <div class="y-c" v-if="!item.imgUrl">
                  <i class="el-icon-plus"></i>
                  <span>添加图片</span>
                </div>
                <el-image
                  v-else
                  :src="item.imgUrl"
                  style="width: 100%; height: 100%"
                  alt="#"
                >
                </el-image>
              </div>

              <!-- 右边标题 -->
              <div class="imgBoxTitle">
                <!-- 标题 -->
                <div class="marB15 x-x">
                  <span class="marR10">标题</span>
                  <el-input
                    v-model="item.title"
                    maxlength="4"
                    placeholder="导航标题最多四个字"
                  ></el-input>
                </div>
                <!-- 链接 -->
                <div class="x-x">
                  <span class="marR10">链接</span>
                  <!--                                    <el-input v-model="item.link"></el-input>-->
                  <el-input
                    class=""
                    style="width: 100%"
                    size="mini"
                    v-model="item.link"
                    :placeholder="item.path == '' ? '选择链接' : '修改'"
                    @focus="handleChange(index)"
                    readonly
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <div class="product-actions">
              <i class="el-icon-error" @click="deleteProduct(index)"></i>
            </div>
          </el-card>
          <div
            class="addImageNav"
            @click="getAddImageNav"
            v-show="data.immageNavList.length < 6"
          >
            <i class="el-icon-plus marL10"></i>
            <span class="addImageNavText">添加图文导航</span>
          </div>
        </div>
      </el-form-item>
      
      

    </el-form>
    <!-- 用户导入图片对话框 -->
    <el-dialog
      title="素材库"
      :visible.sync="materialOpen"
      width="80%"
      :destroy-on-close="true"
    >
      <Material
        :isDialog="true"
        :isRadio="true"
        @accessUrlListCom="getAccessUrlList"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import Material from '@/views/components/material/index.vue' //素材库
import selectLink from '@/views/components/selectLink/index.vue' //链接库
export default {
  name: 'imageNav',
  components: {
    Material,
    selectLink
  },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      openLink: false, // 选择链接弹窗
      indexImg: undefined, //点击的图片下标
      accessUrlList: [], //筛选出来的图片地址数组
      materialOpen: false, //素材库弹窗
      rules: {
        'data.title': [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ]
      },
      isIconImageSty: true, //图片样式点击
      imgListIndex: null // 标记选择链接的ITEN
    }
  },
  methods: {
    //上传图片弹窗取消
    imgUpdateCancel () {
      this.accessUrlList = []
      this.materialOpen = false
    },
    //上传图片弹窗确定
    imgUpdateSubmit () {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.materialOpen = false

      this.data.immageNavList[this.indexImg].imgUrl = this.accessUrlList[0]
      this.templateMsg()
    },
    //图片筛出来的url
    getAccessUrlList (value) {
      this.accessUrlList = value
    },
    //点击图文导航加号
    getImgLibrary (index) {
      this.indexImg = index
      this.materialOpen = true
    },
    // 选择跳转链接改变事件
    handleChange (index) {
      console.log('选链接的item', index)
      this.imgListIndex = index
      this.openLink = true
    },
    // 选择链接
    selectLinkObj (value) {
      console.log('选择的链接', value)
      this.data.immageNavList[this.imgListIndex].link = value.url
      this.templateMsg()
    },
    // 删除图文导航
    deleteProduct (index) {
      this.data.immageNavList.splice(index, 1)
      this.templateMsg()
    },
    // 添加图文导航
    getAddImageNav () {
      this.data.immageNavList.push({
        title: '',
        link: '',
        imgUrl: ''
      })
      this.templateMsg()
    },
    //图片样式变化点击事件
    getIconImageSty (val) {
      this.isIconImageSty = !this.isIconImageSty
      this.data.styleImage = val
      console.log(val)
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    },
    // 设置公告内容
    setMessage (value) {
      this.data.title = value
      this.templateMsg()
    },
    // 背景颜色设置
    selectbgColorChange (value) {
      this.data.style.textColor = value
      this.templateMsg()
    },
    // 文字颜色设置
    selecttextColorChange (value) {
      this.data.style.bgColor = value
      this.templateMsg()
    },
    // 边距设置
    selectpagebackGauge (value, type) {
      this.templateMsg()
    },
    // 图片圆角
    selectImgRadius (value) {
      this.data.style.pageImgRadius = value
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 图片样式鼠标移入事件 */
.iconImageStyle {
  width: 38px;
  height: 30px;
  cursor: pointer;
}

/* 图片样式鼠标点击事件 */
.iconImageSty {
  border: 1px solid #155bd4;
  background-color: #edf4ff;
  color: #155bd4;
}

// 图文导航数组
.imageNavText {
  background-color: #f7f8fa;

  /* 添加图文导航 */
  .addImageNav {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin: 10px 0;
    border: 1px solid #155bd4;
    color: #155bd4;
    background: #fff;
    cursor: pointer;

    .addImageNavText {
      margin-left: 130px;
    }
  }

  /* 删除图标 */
  .el-icon-error {
    font-size: 18px;
    color: #bbbbbb;
    cursor: pointer;
  }

  //导航编辑卡片盒子
  .product-item {
    position: relative;
    /* 鼠标移入删除图标显示 */
    .product-actions {
      display: flex !important;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.6);
      visibility: hidden;
      transition: all 0.2s ease-in-out;
    }

    //图片
    .imgBox {
      width: 60px;
      height: 60px;
      text-align: center;
      border: 1px solid #e5e5e5;
      color: #155bd4;
      font-size: 12px;
      background: #f2f4f6;
      cursor: pointer;
      overflow: hidden;
      //加号
      .el-icon-plus {
        margin-top: 10px;
        font-size: 20px;
      }
    }

    //导航右边标题
    .imgBoxTitle {
      flex: 1;

      span {
        width: 40px;
      }

      .el-input {
        width: 100%;
      }
    }
  }

  /* 鼠标移入样式 */
  .product-item:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  /* 鼠标移入删除图标隐藏 */
  .product-item:hover .product-actions {
    visibility: visible;
  }
}

/* 卡片默认边距0 */
::v-deep .el-card__body {
  padding: 15px;
}
</style>
