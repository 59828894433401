var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "marB20 x-bc" }, [
      _c("div", { staticClass: "fontS14c3" }, [_vm._v("选择样式")]),
      _c("div", { staticClass: "x-x" }, [
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.isLayout === 0 },
            on: {
              click: function ($event) {
                return _vm.getShow("isLayout", 0)
              },
            },
          },
          [_vm._v(" 样式一 ")]
        ),
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.isLayout === 1 },
            on: {
              click: function ($event) {
                return _vm.getShow("isLayout", 1)
              },
            },
          },
          [_vm._v(" 样式二 ")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "x-bc marB20" },
      [
        _c("span", { staticClass: "fontS14c3" }, [_vm._v("收货方式一")]),
        _c("el-input", {
          staticClass: "receiving",
          attrs: {
            size: "mini",
            type: "text",
            placeholder: "请输入内容",
            maxlength: "4",
            "show-word-limit": "",
          },
          model: {
            value: _vm.data.receivingMethod1,
            callback: function ($$v) {
              _vm.$set(_vm.data, "receivingMethod1", $$v)
            },
            expression: "data.receivingMethod1",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "x-bc marB20" },
      [
        _c("span", { staticClass: "fontS14c3" }, [_vm._v("收货方式二")]),
        _c("el-input", {
          staticClass: "receiving",
          attrs: {
            size: "mini",
            type: "text",
            placeholder: "请输入内容",
            maxlength: "4",
            "show-word-limit": "",
          },
          model: {
            value: _vm.data.receivingMethod2,
            callback: function ($$v) {
              _vm.$set(_vm.data, "receivingMethod2", $$v)
            },
            expression: "data.receivingMethod2",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }