<template>
  <div>
    <div class="x-bc marB20">
      <span class="fontS14">样式</span>
      <el-radio-group v-model="data.shape" size="mini">
        <el-radio-button label="yuanjiao">圆角</el-radio-button>
        <el-radio-button label="yuanxing">圆形</el-radio-button>
        <el-radio-button label="shuzi">数字</el-radio-button>
      </el-radio-group>
    </div>
    <div class="x-bc marB20">
      <div class="x-f">
        <span class="marR20 label">显示位置</span>
        <span>{{
          data.position === 'left'
            ? '左对齐'
            : data.position === 'center'
            ? '居中显示'
            : data.position === 'right'
            ? '右对齐'
            : '未知对齐方式'
        }}</span>
      </div>
      <div class="x-f btnListBox">
        <div
          class="item x-c"
          v-for="(item, index) in locationList"
          :key="index"
          @click="setLocation(item.style, index)"
          :class="{ borderColor: locationItem == index }"
        >
          <iconpark-icon :name="item.name" size="16px"></iconpark-icon>
        </div>
      </div>
    </div>
    <div class="x-bc">
      <div class="x-f">
        <span class="fontS14 marR20">选中颜色</span>
        <span class="fontS14">{{ data.bgColor }}</span>
      </div>
      <div class="x-f btnListBox">
        <el-color-picker
          v-model="data.bgColor"
          @change="selectbgColorChange"
        ></el-color-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpintu',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      //位置class类
      locationItem: 1,
      locationList: [
        {
          name: 'align-text-left',
          style: 'left'
        },
        {
          name: 'align-text-center',
          style: 'center'
        },
        {
          name: 'align-text-right',
          style: 'right'
        }
      ]
    }
  },
  methods: {
    // 位置设置
    setLocation (str, index) {
      this.data.position = str
      this.locationItem = index
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    },
    // 选中颜色设置
    selectbgColorChange (value) {
      this.data.bgColor = value
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
.btnListBox {
  margin-left: 25px;
  border-radius: 5px;
  .item {
    //width: 38px;
    height: 30px;
    border: 1px solid #ebedf0;
    padding: 0 20px;
    cursor: pointer;
  }
  .borderColor {
    border: 1px solid #1c75f4;
  }
}
</style>
