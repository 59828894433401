var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "marB20" }, [
        _c("div", { staticClass: "flex" }, [
          _c("span", [_vm._v("魔方布局：")]),
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.changeBox },
                  model: {
                    value: _vm.tabPosition,
                    callback: function ($$v) {
                      _vm.tabPosition = $$v
                    },
                    expression: "tabPosition",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    { attrs: { label: "1" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/5be0108e1dc841d2a265ed554f1229bf.png",
                          fit: "fill",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: "2" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/acc2e79d49684920b6876a649156848b.png",
                          fit: "fill",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: "3" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/e9a4cd910e784716bb1a5d732648c7d5.png",
                          fit: "fill",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: "4" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/b2b807e1fc9c4b3d8cd593e0cfd83ef2.png",
                          fit: "fill",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: "5" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "20px", height: "20px" },
                        attrs: {
                          src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/2ecd0d34282348e2afb0341c4d170ed0.png",
                          fit: "fill",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._m(0),
            ],
            1
          ),
        ]),
      ]),
      _c("div", [
        _vm.tabPosition === "1"
          ? _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  class: [
                    "cube",
                    _vm.upIndex + 1 === 1 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(1)
                    },
                  },
                },
                [
                  _vm.data.imgList[0]
                    ? _c(
                        "el-image",
                        {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: _vm.data.imgList[0].url, fit: "cover" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("div", [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c("div", [_vm._v("上传图片")]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  class: [
                    "cube",
                    _vm.upIndex + 1 === 2 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(2)
                    },
                  },
                },
                [
                  _vm.data.imgList[1]
                    ? _c(
                        "el-image",
                        {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: _vm.data.imgList[1].url, fit: "cover" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("div", [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c("div", [_vm._v("上传图片")]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.tabPosition === "2"
          ? _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  class: [
                    "cube-1",
                    _vm.upIndex + 1 === 1 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(1)
                    },
                  },
                },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.data.imgList[0].url, fit: "cover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("div", [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _c("div", [_vm._v("上传图片")]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  class: [
                    "cube-1",
                    _vm.upIndex + 1 === 2 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(2)
                    },
                  },
                },
                [
                  _vm.data.imgList[1]
                    ? _c(
                        "el-image",
                        {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: _vm.data.imgList[1].url, fit: "cover" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("div", [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c("div", [_vm._v("上传图片")]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  class: [
                    "cube-1",
                    _vm.upIndex + 1 === 3 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(3)
                    },
                  },
                },
                [
                  _vm.data.imgList[2]
                    ? _c(
                        "el-image",
                        {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: _vm.data.imgList[2].url, fit: "cover" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("div", [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c("div", [_vm._v("上传图片")]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.tabPosition === "3"
          ? _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  class: [
                    "cube dz",
                    _vm.upIndex + 1 === 1 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(1)
                    },
                  },
                },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.data.imgList[0].url, fit: "cover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("div", [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _c("div", [_vm._v("上传图片")]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "cube-three" }, [
                _c(
                  "div",
                  {
                    class: [
                      "cube-3",
                      _vm.upIndex + 1 === 2 ? "active" : "active-1",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.upImgIndex(2)
                      },
                    },
                  },
                  [
                    _vm.data.imgList[1]
                      ? _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.data.imgList[1].url,
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    class: [
                      "cube-3",
                      _vm.upIndex + 1 === 3 ? "active" : "active-1",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.upImgIndex(3)
                      },
                    },
                  },
                  [
                    _vm.data.imgList[2]
                      ? _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.data.imgList[2].url,
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.tabPosition === "4"
          ? _c("div", [
              _c(
                "div",
                {
                  class: [
                    "cube tz",
                    _vm.upIndex + 1 === 1 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(1)
                    },
                  },
                },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.data.imgList[0].url, fit: "cover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("div", [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _c("div", [_vm._v("上传图片")]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "cube-fro" }, [
                _c(
                  "div",
                  {
                    class: [
                      "cube",
                      _vm.upIndex + 1 === 2 ? "active" : "active-1",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.upImgIndex(2)
                      },
                    },
                  },
                  [
                    _vm.data.imgList[1]
                      ? _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.data.imgList[1].url,
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    class: [
                      "cube",
                      _vm.upIndex + 1 === 3 ? "active" : "active-1",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.upImgIndex(3)
                      },
                    },
                  },
                  [
                    _vm.data.imgList[2]
                      ? _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.data.imgList[2].url,
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.tabPosition === "5"
          ? _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  class: [
                    "cube dz",
                    _vm.upIndex + 1 === 1 ? "active" : "active-1",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.upImgIndex(1)
                    },
                  },
                },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.data.imgList[0].url, fit: "cover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("div", [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _c("div", [_vm._v("上传图片")]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "cube-three" }, [
                _c(
                  "div",
                  {
                    class: [
                      "cube-3",
                      _vm.upIndex + 1 === 2 ? "active" : "active-1",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.upImgIndex(2)
                      },
                    },
                  },
                  [
                    _vm.data.imgList[1]
                      ? _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.data.imgList[1].url,
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "cube-2",
                        _vm.upIndex + 1 === 3 ? "active" : "active-1",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.upImgIndex(3)
                        },
                      },
                    },
                    [
                      _vm.data.imgList[2]
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.data.imgList[2].url,
                                fit: "cover",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("div", [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                    _c("div", [_vm._v("上传图片")]),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: [
                        "cube-2",
                        _vm.upIndex + 1 === 4 ? "active" : "active-1",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.upImgIndex(4)
                        },
                      },
                    },
                    [
                      _vm.data.imgList[3]
                        ? _c(
                            "el-image",
                            {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.data.imgList[3].url,
                                fit: "cover",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("div", [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                    _c("div", [_vm._v("上传图片")]),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "marT20", staticStyle: { background: "#F8F9FF" } },
          [
            _c(
              "div",
              {
                staticClass: "boxDetail marB10 flex",
                staticStyle: { "align-items": "center", padding: "10px" },
              },
              [
                _c("div", { staticClass: "x-x marB10" }, [
                  _c(
                    "div",
                    {
                      staticClass: "selectIconBox x-fc active-1",
                      staticStyle: { position: "relative" },
                    },
                    [
                      _c(
                        "el-image",
                        {
                          staticStyle: { width: "70px", height: "70px" },
                          attrs: { src: _vm.boxes.url, fit: "cover" },
                          on: {
                            click: function ($event) {
                              return _vm.getReplace()
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot-1",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("div", [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c("div", [_vm._v("上传图片")]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.boxes.url,
                              expression: "boxes.url",
                            },
                          ],
                          staticClass: "replace",
                          on: {
                            click: function ($event) {
                              return _vm.getReplace()
                            },
                          },
                        },
                        [_vm._v("替换")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            "text-align": "right",
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.boxes.url,
                                expression: "boxes.url",
                              },
                            ],
                            staticClass: "el-icon-delete",
                            on: { click: _vm.delIcon },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticStyle: { "margin-left": "10px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "x-f link",
                      staticStyle: { color: "cornflowerblue" },
                      on: {
                        click: function ($event) {
                          return _vm.getOpenLink()
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          size: "mini",
                          readonly: "",
                          placeholder: "选择链接",
                        },
                        model: {
                          value: _vm.boxes.link.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.boxes.link, "name", $$v)
                          },
                          expression: "boxes.link.name",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("内边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 10 },
              model: {
                value: _vm.data.style.marO,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "marO", $$v)
                },
                expression: "data.style.marO",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("上边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 50 },
              model: {
                value: _vm.data.style.marT,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "marT", $$v)
                },
                expression: "data.style.marT",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("下边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 50 },
              model: {
                value: _vm.data.style.marB,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "marB", $$v)
                },
                expression: "data.style.marB",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("左边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 25 },
              model: {
                value: _vm.data.style.marL,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "marL", $$v)
                },
                expression: "data.style.marL",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("右边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 25 },
              model: {
                value: _vm.data.style.marR,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "marR", $$v)
                },
                expression: "data.style.marR",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 radiuDistance" }, [
              _vm._v("圆角"),
            ]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 50 },
              model: {
                value: _vm.data.style.borRadius,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "borRadius", $$v)
                },
                expression: "data.style.borRadius",
              },
            }),
          ],
          1
        ),
      ]),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材库",
            visible: _vm.openIcon,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openIcon = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "space-between" } },
      [
        _c("span", [_vm._v("一行两个")]),
        _c("span", [_vm._v("一行三个")]),
        _c("span", [_vm._v("左一右二")]),
        _c("span", [_vm._v("上一下二")]),
        _c("span", [_vm._v("左一右三")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }