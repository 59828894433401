var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mofang" },
    [
      _c("MySysForm", {
        attrs: { options: _vm.options },
        on: { change: _vm.change },
        model: {
          value: _vm.itemData,
          callback: function ($$v) {
            _vm.itemData = $$v
          },
          expression: "itemData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }