<template>
    <div style="width: 100%">
        <!-- 沉浸式轮播图 -->
        <div class="marB20">
            <div class="fontS14c3">组件样式:</div>
            <div class="flex marT20">
                <div>
                    <el-button type="primary" style="width: 200px;" @click="dialogVisible = true">修改样式</el-button>
                </div>
                <div style="margin-left: 10px;">当前样式：{{ dialogList[data.type - 1].name }}</div>
            </div>
        </div>
        <!-- 轮播点设置 -->
        <div>
            <div class="fontS14c3">轮播点设置:</div>
            <div class="flex marT20">
                <p>样式：</p>
                <el-radio-group v-model="activeName" @input="handleClick">
                    <el-radio-button label="•••"></el-radio-button>
                    <el-radio-button label="1/5"></el-radio-button>
                </el-radio-group>
            </div>
            <div class="flex marT20">
                <p>选中颜色：</p>
                <el-color-picker v-model="data.style.bgColor"></el-color-picker>
                <el-input v-model="data.style.bgColor" style="width: 120px;"></el-input>
            </div>
        </div>
        <!-- 内容 -->
        <div>
            <div class="fontS14c3 marT20">内容: </div>
            <div @mouseover="handleMouseOver(index)" class="boxDetail marB10 flex" v-for="(item, index) in data.contentList"
                :key="index">
                <!-- 按钮图标 -->
                <div class="x-x marB10">
                    <div class="selectIconBox x-fc" @click="getReplace(index)">
                        <el-image style="width: 70px; height: 70px" :src="item.icon" fit="cover">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                        <span v-show="item.icon" class="replace">替换</span>
                    </div>
                </div>
                <div style="margin-left: 10px;">
                    <!-- 跳转链接 -->
                    <div class="x-f link" @click="getOpenLink(index)" style="color: cornflowerblue;">
                        <el-input class="inputWidth" size="mini" v-model="item.link.name" readonly placeholder="选择链接" />
                    </div>
                    <!-- 按钮文字 -->
                    <div class="x-f">
                        <span style="color: #ababab">建议图片尺寸宽度750，高度不限</span>
                    </div>
                    <!--  背景颜色 -->
                    <div class="x-bc" v-if="data.type > 1">
                        <div class="x-f">
                            <span class="fontS14c3 marR20">背景颜色:</span>
                            <el-color-picker v-model="item.title" />
                            <span class="fontS14">{{ item.title }}</span>
                        </div>
                    </div>
                </div>
                <!-- 删除 -->
                <i v-show="activeIndex === index && data.contentList.length > 1" class="el-icon-delete"
                    style="position: absolute;top: 0;right: 0;" @click="handDelete(index, data.contentList)">
                </i>
            </div>
        </div>
        <!-- 添加 -->
        <div class="x-fc marB20" v-show="data.contentList.length < 10">
            <el-button size="mini" style="width: 100%" @click="addNav">+ 添加图片({{ data.contentList.length }} / 10)</el-button>
        </div>

        <div>
            <!-- 上边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">上边距</span>
                <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
            </div>
            <!-- 下边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">下边距</span>
                <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
            </div>
            <!-- 左边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">左边距</span>
                <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
            </div>
            <!-- 右边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">右边距</span>
                <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
            </div>
            <!-- 圆角 -->
            <div class="x-f marB20">
                <span class="fontS14 radiuDistance">圆角</span>
                <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
            </div>
        </div>
        <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
        <!-- <selectIcon :OpenIcon.sync="openIcon" @selectIcon="selectIcon" /> -->
        <!-- 图片选择弹窗 -->
        <el-dialog title="素材库" :visible.sync="openIcon" width="80%" :destroy-on-close="true">
            <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
                <el-button @click="imgUpdateCancel">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 样式修改弹窗 -->
        <el-dialog title="样式选择" width="70%" :visible.sync="dialogVisible" center>
            <div class="flex dl-img">
                <div v-for="(item, index) in dialogList" :key="index" @click="checkDlStatus(item)">
                    <div>
                        <div class="dl-img-center flex" :style="{
                            border: dlActive === item.Did ? '1px solid #2d8cf0' : '1px solid #f4f4f4'
                        }">
                            <div>
                                <el-image style="width: 256px;height: 160px;" :src="item.url" fit="cover"></el-image>
                            </div>
                        </div>
                        <span class="dl-text">{{ item.name }}</span>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveDldata()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import selectLink from '@/views/components/selectLink/index.vue' //链接库
import selectIcon from '@/views/components/selectIcon/index.vue' //图标库
import Material from '@/views/components/material/index.vue' //素材库
export default {
    name: 'immersive',
    components: { selectLink, selectIcon, Material },
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            //当前打开的图标库是哪个数据
            upIndex: -1,
            //图标库窗
            openIcon: false,
            linkIndex: -1, //当前打开的链接库是哪个数据
            openLink: false, //链接弹窗
            accessUrlList: [], //筛选出来的图片地址数组
            materialOpen: false, //素材库弹窗
            activeIndex: -1, //移入下标
            activeName: this.data.isIcon ? '•••' : '1/5',
            color1: '#409EFF',
            dialogVisible: false,
            dialogList: [
                { Did: 1, name: ' 样式一', url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-04/c4802dc7964146d2ae0febe5d53dc3df.png' },
                { Did: 2, name: ' 样式二', url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-28/d45d135fc1874bb096f9b1af8cb1bf30.png' },
                { Did: 3, name: ' 样式三', url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-28/5360b7cce5c2414a90d5e321db857af0.png' }
            ],
            dlActive: this.data.type,
        }
    },
    methods: {
        // 选中的轮播点样式
        handleClick(value) {
            this.data.isIcon = !this.data.isIcon
            console.log('---', value, this.data.isIcon);
        },
        getAccessUrlList(value) {
            console.log('选择的图片：', value)
            this.accessUrlList = value
        },
        // 选择图片弹框确定
        imgUpdateSubmit() {
            if (this.accessUrlList.length > 1) {
                return this.$message.error('图片信息只能单选')
            }
            // this.data.style.imgList[this.selectImgIndex].image = this.accessUrlList[0]
            this.data.contentList[this.upIndex].icon = this.accessUrlList[0]
            this.accessUrlList = []
            this.openIcon = false
            this.templateMsg()
        },
        imgUpdateCancel() {
            this.accessUrlList = []
            this.openIcon = false
        },
        checkDlStatus({ Did }) {
            this.dlActive = Did
        },
        saveDldata() {
            this.data.type = this.dlActive
            this.dialogVisible = false
        },


        // //选择好的图标
        // selectIcon(value) {
        //     this.data.contentList[this.upIndex].icon = value
        //     this.templateMsg()
        // },
        // //打开上传图标
        getReplace(index) {
            this.upIndex = index
            this.openIcon = true
        },
        //链接赋值
        selectLinkObj(value) {
            this.data.contentList[this.linkIndex].link = value
            this.templateMsg()
        },
        //打开链接库
        getOpenLink(index) {
            this.openLink = true
            this.linkIndex = index
        },
        //删除
        handDelete(index, rows) {
            rows.splice(index, 1)
        },
        //鼠标移入
        handleMouseOver(index) {
            this.activeIndex = index
        },
        //添加导航
        addNav() {
            this.data.contentList.push({
                title: '#ff3c29', //标题
                link: { url: '', name: '' }, //跳转链接
                icon: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png' //图标
            })
            this.templateMsg()
        },
        //外部是或否
        getShowWithout(type, bol) {
            this.data[type] = bol
            this.templateMsg()
        },
        templateMsg() {
            this.$emit('setTemp', this.data)
        }
    }
}
</script>
  
<style lang="scss" scoped>
//选择图标
.selectIconBox {
    width: 70px;
    height: 70px;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f4f4f4;
    position: relative;

    //替换
    .replace {
        position: absolute;
        height: 20px;
        left: 1px;
        right: 1px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        line-height: 20px;
        margin: 0;
        text-align: center;
        display: none;
    }
}

//替换
.selectIconBox:hover .replace {
    display: inline-block;
}

//简单按钮样式
.simpleBtn {
    text-align: center;
    border: 1px solid #dcdee0;
    height: 32px;
    line-height: 30px;
    padding: 0 16px;

    &.active {
        background: #edf4ff;
        border: 1px solid #155bd4;
        color: #155bd4;
    }
}

//边框详情样式
.boxDetail {
    min-width: 250px;
    padding: 15px;
    background-color: #f8f9ff;
    position: relative;

    .el-icon-error {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
        color: #aaaaaa;
        cursor: pointer;
    }

    .link {
        ::v-deep .el-input--mini .el-input__inner {
            cursor: default;
        }
    }
}

//拖动
.slider {
    width: calc(100% - 106px);
}

//圆角
.radiuDistance {
    margin-right: 62px;
}

/***/
.flex {
    display: flex;
    align-items: center;
}

.dl-img {
    text-align: center;
    justify-content: space-around;

    // padding: 10px;
    .dl-img-center {
        width: 300px;
        height: 200px;
        cursor: pointer;
        overflow: hidden;
        box-sizing: border-box;
        // border: 1px solid #f4f4f4;
        justify-content: center;
        background-color: #FBFCFF;
        border-radius: 10px;
    }

    .dl-text {
        margin-top: 5px;
        display: inline-block;
    }
}

.dialog-footer {
    margin-top: 20px;
    display: inline-block;
}

::v-deep .image-slot {
    text-align: center;
    margin-top: 22px;
}
</style>
  