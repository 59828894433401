<template>
  <div style="width: 100%">
    <!-- 标题颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">标题颜色</span>
        <span class="fontS14">{{ data.contentStyle.titleColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.titleColor"
          @change="
            selectColorChange(
              'data.contentStyle.titleColor',
              data.contentStyle.titleColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 背景颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">背景颜色</span>
        <span class="fontS14">{{ data.contentStyle.bgColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.bgColor"
          @change="
            selectColorChange(
              'data.contentStyle.bgColor',
              data.contentStyle.bgColor
            )
          "
        ></el-color-picker>
      </div>
    </div>

    <div
      class="marB20 x-bc"
      v-for="(item, index) in isDisplayList"
      :key="index"
    >
      <div class="fontS14c3">{{ item.name }}</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: item.model }"
          @click="getShow(item.type, true, index)"
        >
          显示
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !item.model }"
          @click="getShow(item.type, false, index)"
        >
          不显示
        </div>
      </div>
    </div>
    <!-- 上边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">上边距</span>
      <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
    </div>
    <!-- 下边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">下边距</span>
      <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
    </div>
    <!-- 左边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">左边距</span>
      <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
    </div>
    <!-- 右边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">右边距</span>
      <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
    </div>
    <!-- 圆角 -->
    <div class="x-f marB20">
      <span class="fontS14 radiuDistance">圆角</span>
      <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpinpingjia',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      isDisplayList: [
        {
          name: '评价条数',
          model: this.data.contentStyle.isNumberEvaluations,
          type: 'isNumberEvaluations'
        },
        {
          name: '查看更多',
          model: this.data.contentStyle.isReadMore,
          type: 'isReadMore'
        },
        {
          name: '会员等级',
          model: this.data.contentStyle.isVipLevel,
          type: 'isVipLevel'
        },
        {
          name: '评价等级',
          model: this.data.contentStyle.isEvaluationLeve,
          type: 'isEvaluationLeve'
        },
        {
          name: '商品规格',
          model: this.data.contentStyle.isGoodsSpece,
          type: 'isGoodsSpece'
        },
        {
          name: '评价时间',
          model: this.data.contentStyle.isTimeEvaluations,
          type: 'isTimeEvaluations'
        },
        {
          name: '评价图片',
          model: this.data.contentStyle.isImgEvaluations,
          type: 'isImgEvaluations'
        }
      ]
    }
  },
  methods: {
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    getShow (type, bol, index) {
      this.isDisplayList[index].model = bol
      this.data.contentStyle[type] = bol
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    },
    // 颜色设置
    selectColorChange (attribute, value) {
      this[attribute] = value
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//拖动
.slider {
  width: calc(100% - 106px);
}

//圆角
.radiuDistance {
  margin-right: 62px;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
