var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "marB20" }, [
      _c("span", { staticClass: "fontS14c3" }, [_vm._v("侧边导航:")]),
      _c(
        "div",
        { staticClass: "x-fc marT10" },
        _vm._l(_vm.navigationList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "displayMode marR15",
              class: { active: _vm.data.sideNavStyle.sideNavType === index },
              on: {
                click: function ($event) {
                  return _vm.getSideNavListStyle(item.sideNavType)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "icon" },
                [
                  _c("iconpark-icon", {
                    attrs: { name: item.icon, size: "22px" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "text" }, [_vm._v(_vm._s(item.text))]),
            ]
          )
        }),
        0
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.data.goodsStyle.goodsType != 2 &&
              _vm.data.goodsStyle.goodsType != 3,
            expression:
              "data.goodsStyle.goodsType != 2 && data.goodsStyle.goodsType != 3",
          },
        ],
        staticClass: "marB20 x-bc",
      },
      [
        _c("div", { staticClass: "fontS14c3" }, [
          _vm._v("瀑布流方式加载跨分类商品"),
        ]),
        _c("div", { staticClass: "x-x" }, [
          _c(
            "div",
            {
              staticClass: "simpleBtn cursorP",
              class: { active: _vm.data.sideNavStyle.isWaterfallFlow },
              on: {
                click: function ($event) {
                  return _vm.getShow("sideNavStyle", "isWaterfallFlow", true)
                },
              },
            },
            [_vm._v(" 启用 ")]
          ),
          _c(
            "div",
            {
              staticClass: "simpleBtn cursorP",
              class: { active: !_vm.data.sideNavStyle.isWaterfallFlow },
              on: {
                click: function ($event) {
                  return _vm.getShow("sideNavStyle", "isWaterfallFlow", false)
                },
              },
            },
            [_vm._v(" 不启用 ")]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.sideNavStyle.isWaterfallFlow,
            expression: "data.sideNavStyle.isWaterfallFlow",
          },
        ],
        staticClass: "waterfallLoadingMode marB20",
      },
      [
        _c("span", { staticClass: "fontS14c3" }, [_vm._v("加载方式:")]),
        _c(
          "el-radio-group",
          {
            staticClass: "marT10 x-f",
            model: {
              value: _vm.data.sideNavStyle.loadingMode,
              callback: function ($$v) {
                _vm.$set(_vm.data.sideNavStyle, "loadingMode", $$v)
              },
              expression: "data.sideNavStyle.loadingMode",
            },
          },
          [
            _c(
              "el-radio",
              { attrs: { label: 0 } },
              [
                _vm._v("强滑动加载跨分类商品 "),
                _c("el-tooltip", { attrs: { placement: "top" } }, [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 流畅度一般，但是支持无限商品。 "),
                    _c("br"),
                    _vm._v(" 请使用2020.12.18及之后发布的小程序模版。 "),
                  ]),
                  _c("i", {
                    staticClass: "iconfont icon-wenhao",
                    staticStyle: { color: "#a4a4a4" },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-radio",
              { attrs: { label: 1 } },
              [
                _vm._v("一次性加载全部商品 "),
                _c("el-tooltip", { attrs: { placement: "top" } }, [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 商品少于200时流畅度最优，但是有以下局限： "),
                    _c("br"),
                    _vm._v(" 1、单分类最多50个商品，最多20个分类。 "),
                    _c("br"),
                    _vm._v(
                      " 2、分类只显示一级分类，ps 二级以下分类会平铺到一级显示。 "
                    ),
                    _c("br"),
                    _vm._v(" 3、商品不支持开启排序条件。 "),
                    _c("br"),
                    _vm._v(" 4、暂不支持不支持表情分类。 "),
                    _c("br"),
                    _vm._v(" 请使用2020.12.18及之后发布的小程序模版。 "),
                  ]),
                  _c("i", {
                    staticClass: "iconfont icon-wenhao",
                    staticStyle: { color: "#a4a4a4" },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "goodsDisplayMode marB20" }, [
      _c("span", { staticClass: "fontS14c3" }, [_vm._v("商品展示样式:")]),
      _c(
        "div",
        { staticClass: "x-fc marT10" },
        _vm._l(_vm.listStyle, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "displayMode marR20",
              class: { active: _vm.data.goodsStyle.goodsType === index },
              on: {
                click: function ($event) {
                  return _vm.getGoodsListStyle(item.goodsType)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "icon" },
                [
                  _c("iconpark-icon", {
                    attrs: { name: item.icon, size: "22px" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "text" }, [_vm._v(_vm._s(item.text))]),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "marB20 x-bc" }, [
      _c("div", { staticClass: "fontS14c3" }, [_vm._v("库存")]),
      _c("div", { staticClass: "x-x" }, [
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.goodsStyle.isInventory },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isInventory", true)
              },
            },
          },
          [_vm._v(" 显示 ")]
        ),
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: !_vm.data.goodsStyle.isInventory },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isInventory", false)
              },
            },
          },
          [_vm._v(" 不显示 ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "marB20 x-bc" }, [
      _c("div", { staticClass: "fontS14c3" }, [_vm._v("是否开启排序条件")]),
      _c("div", { staticClass: "x-x" }, [
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.goodsStyle.isSortData },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isSortData", true)
              },
            },
          },
          [_vm._v(" 显示 ")]
        ),
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: !_vm.data.goodsStyle.isSortData },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isSortData", false)
              },
            },
          },
          [_vm._v(" 不显示 ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "marB20 x-bc" }, [
      _c("div", { staticClass: "fontS14c3" }, [_vm._v("销量")]),
      _c("div", { staticClass: "x-x" }, [
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.goodsStyle.isSalesVolume },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isSalesVolume", true)
              },
            },
          },
          [_vm._v(" 显示 ")]
        ),
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: !_vm.data.goodsStyle.isSalesVolume },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isSalesVolume", false)
              },
            },
          },
          [_vm._v(" 不显示 ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "marB20 x-bc" }, [
      _c("div", { staticClass: "fontS14c3" }, [_vm._v("价格标签")]),
      _c("div", { staticClass: "x-x" }, [
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.goodsStyle.isPriceTag },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isPriceTag", true)
              },
            },
          },
          [_vm._v(" 显示 ")]
        ),
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: !_vm.data.goodsStyle.isPriceTag },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isPriceTag", false)
              },
            },
          },
          [_vm._v(" 不显示 ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "marB20 x-bc" }, [
      _c("div", { staticClass: "fontS14c3" }, [
        _vm._v("分类管理上传的分类海报"),
      ]),
      _c("div", { staticClass: "x-x" }, [
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.goodsStyle.isPoster },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isPoster", true)
              },
            },
          },
          [_vm._v(" 显示 ")]
        ),
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: !_vm.data.goodsStyle.isPoster },
            on: {
              click: function ($event) {
                return _vm.getShow("goodsStyle", "isPoster", false)
              },
            },
          },
          [_vm._v(" 不显示 ")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "marB20 x-bc" },
      [
        _c("span", { staticClass: "fontS14c3" }, [_vm._v("标题字体大小")]),
        _c(
          "el-select",
          {
            staticClass: "titleSizeSelect",
            attrs: { size: "mini", filterable: "" },
            model: {
              value: _vm.data.goodsStyle.titleSize,
              callback: function ($$v) {
                _vm.$set(_vm.data.goodsStyle, "titleSize", $$v)
              },
              expression: "data.goodsStyle.titleSize",
            },
          },
          _vm._l(_vm.titleSizeList, function (item) {
            return _c("el-option", {
              key: item,
              attrs: { label: item, value: item },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "marB20 x-bc" },
      [
        _c("span", { staticClass: "fontS14c3" }, [_vm._v("价格字体大小")]),
        _c(
          "el-select",
          {
            staticClass: "titleSizeSelect",
            attrs: { size: "mini", filterable: "" },
            model: {
              value: _vm.data.goodsStyle.priceSize,
              callback: function ($$v) {
                _vm.$set(_vm.data.goodsStyle, "priceSize", $$v)
              },
              expression: "data.goodsStyle.priceSize",
            },
          },
          _vm._l(_vm.priceSizeList, function (item) {
            return _c("el-option", {
              key: item,
              attrs: { label: item, value: item },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("标题颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.goodsStyle.titleColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.data.goodsStyle.titleColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.goodsStyle, "titleColor", $$v)
              },
              expression: "data.goodsStyle.titleColor",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("价格标签颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.goodsStyle.priceTagColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.data.goodsStyle.priceTagColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.goodsStyle, "priceTagColor", $$v)
              },
              expression: "data.goodsStyle.priceTagColor",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("价格颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.goodsStyle.priceColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.data.goodsStyle.priceColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.goodsStyle, "priceColor", $$v)
              },
              expression: "data.goodsStyle.priceColor",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }