var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "x-bc marB20" }, [
        _c("div", [
          _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("标题颜色")]),
          _c("span", { staticClass: "fontS14" }, [
            _vm._v(_vm._s(_vm.data.contentStyle.titleColor)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "x-f marL10" },
          [
            _c("el-color-picker", {
              on: {
                change: function ($event) {
                  return _vm.selectColorChange(
                    "data.contentStyle.titleColor",
                    _vm.data.contentStyle.titleColor
                  )
                },
              },
              model: {
                value: _vm.data.contentStyle.titleColor,
                callback: function ($$v) {
                  _vm.$set(_vm.data.contentStyle, "titleColor", $$v)
                },
                expression: "data.contentStyle.titleColor",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "x-bc marB20" }, [
        _c("div", [
          _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("背景颜色")]),
          _c("span", { staticClass: "fontS14" }, [
            _vm._v(_vm._s(_vm.data.contentStyle.bgColor)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "x-f marL10" },
          [
            _c("el-color-picker", {
              on: {
                change: function ($event) {
                  return _vm.selectColorChange(
                    "data.contentStyle.bgColor",
                    _vm.data.contentStyle.bgColor
                  )
                },
              },
              model: {
                value: _vm.data.contentStyle.bgColor,
                callback: function ($$v) {
                  _vm.$set(_vm.data.contentStyle, "bgColor", $$v)
                },
                expression: "data.contentStyle.bgColor",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._l(_vm.isDisplayList, function (item, index) {
        return _c("div", { key: index, staticClass: "marB20 x-bc" }, [
          _c("div", { staticClass: "fontS14c3" }, [_vm._v(_vm._s(item.name))]),
          _c("div", { staticClass: "x-x" }, [
            _c(
              "div",
              {
                staticClass: "simpleBtn cursorP",
                class: { active: item.model },
                on: {
                  click: function ($event) {
                    return _vm.getShow(item.type, true, index)
                  },
                },
              },
              [_vm._v(" 显示 ")]
            ),
            _c(
              "div",
              {
                staticClass: "simpleBtn cursorP",
                class: { active: !item.model },
                on: {
                  click: function ($event) {
                    return _vm.getShow(item.type, false, index)
                  },
                },
              },
              [_vm._v(" 不显示 ")]
            ),
          ]),
        ])
      }),
      _c(
        "div",
        { staticClass: "x-f marB20" },
        [
          _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("上边距")]),
          _c("el-slider", {
            staticClass: "slider",
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.data.marT,
              callback: function ($$v) {
                _vm.$set(_vm.data, "marT", $$v)
              },
              expression: "data.marT",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f marB20" },
        [
          _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("下边距")]),
          _c("el-slider", {
            staticClass: "slider",
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.data.marB,
              callback: function ($$v) {
                _vm.$set(_vm.data, "marB", $$v)
              },
              expression: "data.marB",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f marB20" },
        [
          _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("左边距")]),
          _c("el-slider", {
            staticClass: "slider",
            attrs: { min: 0, max: 25 },
            model: {
              value: _vm.data.marL,
              callback: function ($$v) {
                _vm.$set(_vm.data, "marL", $$v)
              },
              expression: "data.marL",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f marB20" },
        [
          _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("右边距")]),
          _c("el-slider", {
            staticClass: "slider",
            attrs: { min: 0, max: 25 },
            model: {
              value: _vm.data.marR,
              callback: function ($$v) {
                _vm.$set(_vm.data, "marR", $$v)
              },
              expression: "data.marR",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f marB20" },
        [
          _c("span", { staticClass: "fontS14 radiuDistance" }, [
            _vm._v("圆角"),
          ]),
          _c("el-slider", {
            staticClass: "slider",
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.data.borRadius,
              callback: function ($$v) {
                _vm.$set(_vm.data, "borRadius", $$v)
              },
              expression: "data.borRadius",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }