<template>
  <div style="width: 100%">
    <!-- 标题颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">标题颜色</span>
        <span class="fontS14">{{ data.contentStyle.titleColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.titleColor"
          @change="
            selectColorChange(
              'data.contentStyle.titleColor',
              data.contentStyle.titleColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 文字颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">文字颜色</span>
        <span class="fontS14">{{ data.contentStyle.textColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.textColor"
          @change="
            selectColorChange(
              'data.contentStyle.textColor',
              data.contentStyle.textColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 背景颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">背景颜色</span>
        <span class="fontS14">{{ data.contentStyle.bgColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.bgColor"
          @change="
            selectColorChange(
              'data.contentStyle.bgColor',
              data.contentStyle.bgColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 上边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">上边距</span>
      <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
    </div>
    <!-- 下边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">下边距</span>
      <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
    </div>
    <!-- 左边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">左边距</span>
      <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
    </div>
    <!-- 右边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">右边距</span>
      <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
    </div>
    <!-- 圆角 -->
    <div class="x-f marB20">
      <span class="fontS14 radiuDistance">圆角</span>
      <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpinguige',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {}
  },
  methods: {
    // 位置设置
    setLocation (str, index) {
      this.data.position = str
      this.locationItem = index
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    },
    // 颜色设置
    selectColorChange (attribute, value) {
      this[attribute] = value
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
//拖动
.slider {
  width: calc(100% - 106px);
}
//圆角
.radiuDistance {
  margin-right: 62px;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
