<template>
  <div style="width: 100%">
    <!-- 样式 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">选择样式</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.isLayout === 0 }"
          @click="getShow('isLayout', 0)"
        >
          样式一
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.isLayout === 1 }"
          @click="getShow('isLayout', 1)"
        >
          样式二
        </div>
      </div>
    </div>
    <!-- 收货方式1 -->
    <div class="x-bc marB20">
      <span class="fontS14c3">收货方式一</span>
      <el-input
        v-model="data.receivingMethod1"
        size="mini"
        type="text"
        placeholder="请输入内容"
        maxlength="4"
        show-word-limit
        class="receiving"
      />
    </div>
    <!-- 收货方式2 -->
    <div class="x-bc marB20">
      <span class="fontS14c3">收货方式二</span>
      <el-input
        v-model="data.receivingMethod2"
        size="mini"
        type="text"
        placeholder="请输入内容"
        maxlength="4"
        show-word-limit
        class="receiving"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'fuwufangshi',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {}
  },
  methods: {
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    getShow (type, bol) {
      this.data[type] = bol
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//收货方式
.receiving {
  width: 140px;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
