var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公告", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                on: { change: _vm.setMessage },
                model: {
                  value: _vm.data.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "title", $$v)
                  },
                  expression: "data.title",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("字体颜色"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.textColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c("el-color-picker", {
                    on: { change: _vm.selectbgColorChange },
                    model: {
                      value: _vm.data.style.textColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "textColor", $$v)
                      },
                      expression: "data.style.textColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("背景颜色"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.bgColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c("el-color-picker", {
                    on: { change: _vm.selecttextColorChange },
                    model: {
                      value: _vm.data.style.bgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "bgColor", $$v)
                      },
                      expression: "data.style.bgColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }