var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "marB20 x-bc" }, [
      _c("div", { staticClass: "fontS14c3" }, [_vm._v("商品单位")]),
      _c("div", { staticClass: "x-x" }, [
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: _vm.data.isUnit },
            on: {
              click: function ($event) {
                return _vm.getShow("isUnit", true)
              },
            },
          },
          [_vm._v(" 显示 ")]
        ),
        _c(
          "div",
          {
            staticClass: "simpleBtn cursorP",
            class: { active: !_vm.data.isUnit },
            on: {
              click: function ($event) {
                return _vm.getShow("isUnit", false)
              },
            },
          },
          [_vm._v(" 不显示 ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "marB20" }, [
      _c("div", { staticClass: "marB20 x-bc" }, [
        _c("div", { staticClass: "fontS14c3" }, [_vm._v("营销信息")]),
        _c("div", { staticClass: "x-x" }, [
          _c(
            "div",
            {
              staticClass: "simpleBtn cursorP",
              class: { active: _vm.data.isMarketing },
              on: {
                click: function ($event) {
                  return _vm.getShow("isMarketing", true)
                },
              },
            },
            [_vm._v(" 显示 ")]
          ),
          _c(
            "div",
            {
              staticClass: "simpleBtn cursorP",
              class: { active: !_vm.data.isMarketing },
              on: {
                click: function ($event) {
                  return _vm.getShow("isMarketing", false)
                },
              },
            },
            [_vm._v(" 不显示 ")]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data.isMarketing,
              expression: "data.isMarketing",
            },
          ],
          staticClass: "boxDetail",
        },
        [
          _c("div", { staticClass: "x-bc marB10" }, [
            _c("div", { staticClass: "x-f" }, [
              _c("span", { staticClass: "fontS14 marR20" }, [
                _vm._v("字体颜色"),
              ]),
              _c("span", { staticClass: "fontS14" }, [
                _vm._v(_vm._s(_vm.data.marketingStyle.textColor)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "x-f marL10" },
              [
                _c("el-color-picker", {
                  on: {
                    change: function ($event) {
                      return _vm.selectColorChange(
                        "data.marketingStyle.textColor",
                        _vm.data.marketingStyle.textColor
                      )
                    },
                  },
                  model: {
                    value: _vm.data.marketingStyle.textColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.marketingStyle, "textColor", $$v)
                    },
                    expression: "data.marketingStyle.textColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "x-bc" }, [
            _c("div", { staticClass: "x-f" }, [
              _c("span", { staticClass: "fontS14 marR20" }, [
                _vm._v("背景颜色"),
              ]),
              _c("span", { staticClass: "fontS14" }, [
                _vm._v(_vm._s(_vm.data.marketingStyle.bgColor)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "x-f marL10" },
              [
                _c("el-color-picker", {
                  on: {
                    change: function ($event) {
                      return _vm.selectColorChange(
                        "data.marketingStyle.bgColor",
                        _vm.data.marketingStyle.bgColor
                      )
                    },
                  },
                  model: {
                    value: _vm.data.marketingStyle.bgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.marketingStyle, "bgColor", $$v)
                    },
                    expression: "data.marketingStyle.bgColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "marB20" }, [
      _c("div", { staticClass: "marB20 x-bc" }, [
        _c("div", { staticClass: "fontS14c3" }, [_vm._v("服务")]),
        _c("div", { staticClass: "x-x" }, [
          _c(
            "div",
            {
              staticClass: "simpleBtn cursorP",
              class: { active: _vm.data.isService },
              on: {
                click: function ($event) {
                  return _vm.getShow("isService", true)
                },
              },
            },
            [_vm._v(" 显示 ")]
          ),
          _c(
            "div",
            {
              staticClass: "simpleBtn cursorP",
              class: { active: !_vm.data.isService },
              on: {
                click: function ($event) {
                  return _vm.getShow("isService", false)
                },
              },
            },
            [_vm._v(" 不显示 ")]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data.isService,
              expression: "data.isService",
            },
          ],
          staticClass: "boxDetail",
        },
        [
          _c(
            "div",
            { staticClass: "x-bc marB10" },
            [
              _c("span", { staticClass: "fontS14 marR20" }, [
                _vm._v("选择样式"),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.data.serviceStyle.shape,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.serviceStyle, "shape", $$v)
                    },
                    expression: "data.serviceStyle.shape",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    { attrs: { label: "duihao" } },
                    [
                      _c("iconpark-icon", {
                        attrs: { color: "#333333", name: "check-one" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: "dian" } },
                    [
                      _c("iconpark-icon", {
                        attrs: { color: "#333333", name: "dot" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "x-bc marB10" }, [
            _c("div", [
              _c("span", { staticClass: "fontS14 marR20" }, [
                _vm._v("标签颜色"),
              ]),
              _c("span", { staticClass: "fontS14" }, [
                _vm._v(_vm._s(_vm.data.serviceStyle.labelColor)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "x-f marL10" },
              [
                _c("el-color-picker", {
                  on: {
                    change: function ($event) {
                      return _vm.selectColorChange(
                        "data.serviceStyle.labelColor",
                        _vm.data.serviceStyle.labelColor
                      )
                    },
                  },
                  model: {
                    value: _vm.data.serviceStyle.labelColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.serviceStyle, "labelColor", $$v)
                    },
                    expression: "data.serviceStyle.labelColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "x-bc marB10" }, [
            _c("div", [
              _c("span", { staticClass: "fontS14 marR20" }, [
                _vm._v("字体颜色"),
              ]),
              _c("span", { staticClass: "fontS14" }, [
                _vm._v(_vm._s(_vm.data.serviceStyle.textColor)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "x-f marL10" },
              [
                _c("el-color-picker", {
                  on: {
                    change: function ($event) {
                      return _vm.selectColorChange(
                        "data.serviceStyle.textColor",
                        _vm.data.serviceStyle.textColor
                      )
                    },
                  },
                  model: {
                    value: _vm.data.serviceStyle.textColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.serviceStyle, "textColor", $$v)
                    },
                    expression: "data.serviceStyle.textColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "x-bc" }, [
            _c("div", [
              _c("span", { staticClass: "fontS14 marR20" }, [
                _vm._v("背景颜色"),
              ]),
              _c("span", { staticClass: "fontS14" }, [
                _vm._v(_vm._s(_vm.data.serviceStyle.bgColor)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "x-f marL10" },
              [
                _c("el-color-picker", {
                  on: {
                    change: function ($event) {
                      return _vm.selectColorChange(
                        "data.serviceStyle.bgColor",
                        _vm.data.serviceStyle.bgColor
                      )
                    },
                  },
                  model: {
                    value: _vm.data.serviceStyle.bgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.serviceStyle, "bgColor", $$v)
                    },
                    expression: "data.serviceStyle.bgColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("标题颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.contentStyle.titleColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            on: {
              change: function ($event) {
                return _vm.selectColorChange(
                  "data.contentStyle.titleColor",
                  _vm.data.contentStyle.titleColor
                )
              },
            },
            model: {
              value: _vm.data.contentStyle.titleColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.contentStyle, "titleColor", $$v)
              },
              expression: "data.contentStyle.titleColor",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("介绍颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.contentStyle.subtitleColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            on: {
              change: function ($event) {
                return _vm.selectColorChange(
                  "data.contentStyle.subtitleColor",
                  _vm.data.contentStyle.subtitleColor
                )
              },
            },
            model: {
              value: _vm.data.contentStyle.subtitleColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.contentStyle, "subtitleColor", $$v)
              },
              expression: "data.contentStyle.subtitleColor",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("价格颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.contentStyle.priceColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            on: {
              change: function ($event) {
                return _vm.selectColorChange(
                  "data.contentStyle.priceColor",
                  _vm.data.contentStyle.priceColor
                )
              },
            },
            model: {
              value: _vm.data.contentStyle.priceColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.contentStyle, "priceColor", $$v)
              },
              expression: "data.contentStyle.priceColor",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("销量颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.contentStyle.salesVolumeColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            on: {
              change: function ($event) {
                return _vm.selectColorChange(
                  "data.contentStyle.salesVolumeColor",
                  _vm.data.contentStyle.salesVolumeColor
                )
              },
            },
            model: {
              value: _vm.data.contentStyle.salesVolumeColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.contentStyle, "salesVolumeColor", $$v)
              },
              expression: "data.contentStyle.salesVolumeColor",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("背景颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.contentStyle.bgColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f marL10" },
        [
          _c("el-color-picker", {
            on: {
              change: function ($event) {
                return _vm.selectColorChange(
                  "data.contentStyle.bgColor",
                  _vm.data.contentStyle.bgColor
                )
              },
            },
            model: {
              value: _vm.data.contentStyle.bgColor,
              callback: function ($$v) {
                _vm.$set(_vm.data.contentStyle, "bgColor", $$v)
              },
              expression: "data.contentStyle.bgColor",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "x-f marB20" },
      [
        _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("上边距")]),
        _c("el-slider", {
          staticClass: "slider",
          attrs: { min: 0, max: 50 },
          model: {
            value: _vm.data.marT,
            callback: function ($$v) {
              _vm.$set(_vm.data, "marT", $$v)
            },
            expression: "data.marT",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "x-f marB20" },
      [
        _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("下边距")]),
        _c("el-slider", {
          staticClass: "slider",
          attrs: { min: 0, max: 50 },
          model: {
            value: _vm.data.marB,
            callback: function ($$v) {
              _vm.$set(_vm.data, "marB", $$v)
            },
            expression: "data.marB",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "x-f marB20" },
      [
        _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("左边距")]),
        _c("el-slider", {
          staticClass: "slider",
          attrs: { min: 0, max: 25 },
          model: {
            value: _vm.data.marL,
            callback: function ($$v) {
              _vm.$set(_vm.data, "marL", $$v)
            },
            expression: "data.marL",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "x-f marB20" },
      [
        _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("右边距")]),
        _c("el-slider", {
          staticClass: "slider",
          attrs: { min: 0, max: 25 },
          model: {
            value: _vm.data.marR,
            callback: function ($$v) {
              _vm.$set(_vm.data, "marR", $$v)
            },
            expression: "data.marR",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "x-f marB20" },
      [
        _c("span", { staticClass: "fontS14 radiuDistance" }, [_vm._v("圆角")]),
        _c("el-slider", {
          staticClass: "slider",
          attrs: { min: 0, max: 50 },
          model: {
            value: _vm.data.borRadius,
            callback: function ($$v) {
              _vm.$set(_vm.data, "borRadius", $$v)
            },
            expression: "data.borRadius",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }