<template>
  <!-- 右边控制商品样式组件 -->
  <div class="goods">
    <el-form :model="data">
      <!-- <div class="control-group x-f">
        <div class="control-group__header">拼团类型</div>
        <div class="list-radio x-w">
          <el-radio-group v-model="data.groupingType">
            <el-radio
              class="marB10"
              v-for="(item, index) in groupingTypeList"
              :key="index"
              :label="item.id"
              :disabled="item.id != 1"
            >
              {{ item.text }}
            </el-radio>
          </el-radio-group>
        </div>
      </div> -->
      <div class="control-group x-f">
        <div class="control-group__header">拼团类型</div>
        <div class="list-radio x-f">
          <el-radio class="" v-model="data.groupingType2" :label="1">手动添加</el-radio>
          <div class="x-f">
            <el-radio class="" v-model="data.groupingType2" label="2" disabled
              >自动获取</el-radio
            >
            <el-tooltip
              class="item"
              effect="dark"
              content="系统自动获取店铺拼团商品, 建议选择手动添加"
              placement="top"
            >
              <i class="el-icon-question" style="color: #bebebe"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="selectGoods x-f">
        <div class="select-btn" @click="openDialog">
          选择商品
          <span class="select-num"
            >({{ data.goodList.length < 1 ? 0 : data.goodList.length }}/100)</span
          >
        </div>
      </div>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <div class="module-title">样式设置</div>
      <div class="control-group">
        <div class="control-group__header">列表样式</div>
        <div class="control-group__content">
          <div class="list-radio x-bc">
            <div
              class="item-radio cr"
              v-for="(item, index) in listStyle"
              :key="item.id"
              :class="{ active: data.curListStyleIndex === index }"
              @click="clickListStyle(index)"
            >
              <div class="icon">
                <iconpark-icon :name="item.icon" size="22px"></iconpark-icon>
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="control-group">
        <div class="control-group__header">商品样式</div>
        <div class="control-group__content">
          <div class="list-goods x-w">
            <div
              class="item-goods cr"
              v-for="(item, index) in goodsStyle"
              :key="item"
              :class="{ active: data.curGoodsStyleIndex === index }"
              @click="clickGoodsStyle(index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="control-group x-bc">
        <div class="control-group__header">购买按钮样式</div>
        <div class="control-group__content">
          <div class="shoppingCart x-bc cr" @click="selectShoppingCart">
            <div class="left x-f">
              <iconpark-icon
                :name="shoppingCart[data.curShoppingCardIndex].icon"
                class="shoppingIcon"
                :color="shoppingCart[data.curShoppingCardIndex].color || '#fe5a34'"
                size="24px"
              ></iconpark-icon>
              <div class="text">
                {{ shoppingCart[data.curShoppingCardIndex].text }}
              </div>
            </div>
            <iconpark-icon name="down"></iconpark-icon>
          </div>
        </div>
      </div>
      <!-- 选择购物车区域 -->
      <div class="select-shoppingCart" v-show="data.selectShoppingCart">
        <div
          class="shoppingCartItem"
          v-for="(item, index) in shoppingCart"
          :key="item.id"
          @click="pitchShoppingCart(index)"
        >
          <iconpark-icon
            class="shoppingCartIcon"
            :name="item.icon"
            :color="item.color || '#fe5a34'"
            size="24px"
          ></iconpark-icon>
          {{ item.text }}
        </div>
      </div>
      <!-- 购物车自定义按钮文字 -->
      <div class="control-group x-bc" v-if="isDiyBtnText">
        <div class="control-group__header">按钮文字</div>
        <div class="control-group__content">
          <el-input v-model="data.btnText" style="width: 160px"></el-input>
        </div>
      </div>
      <!-- 更多设置区域 -->
      <div v-show="moreSetArea">
        <div class="control-group x-bc">
          <div class="control-group__header">商品名称</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.isShowName }"
                @click="moreSetAreaClick('isShowName', true)"
              >
                显示
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.isShowName }"
                @click="moreSetAreaClick('isShowName', false)"
              >
                不显示
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">商品描述</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.isShowDescribe }"
                @click="moreSetAreaClick('isShowDescribe', true)"
              >
                显示
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.isShowDescribe }"
                @click="moreSetAreaClick('isShowDescribe', false)"
              >
                不显示
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">拼团价格</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.isShowFightGroupPrice }"
                @click="moreSetAreaClick('isShowFightGroupPrice', true)"
              >
                显示
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.isShowFightGroupPrice }"
                @click="moreSetAreaClick('isShowFightGroupPrice', false)"
              >
                不显示
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">划线价</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.isShowOriginalPrice }"
                @click="moreSetAreaClick('isShowOriginalPrice', true)"
              >
                显示
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.isShowOriginalPrice }"
                @click="moreSetAreaClick('isShowOriginalPrice', false)"
              >
                不显示
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">抢购倒计时</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.isShowCountDown }"
                @click="moreSetAreaClick('isShowCountDown', true)"
              >
                显示
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.isShowCountDown }"
                @click="moreSetAreaClick('isShowCountDown', false)"
              >
                不显示
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">已团人数</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.alreadyHaveNum }"
                @click="moreSetAreaClick('alreadyHaveNum', true)"
              >
                显示
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.alreadyHaveNum }"
                @click="moreSetAreaClick('alreadyHaveNum', false)"
              >
                不显示
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">售完商品置底显示</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.isSellOutBottom }"
                @click="moreSetAreaClick('isSellOutBottom', true)"
              >
                置底
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.isSellOutBottom }"
                @click="moreSetAreaClick('isSellOutBottom', false)"
              >
                不置底
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">商品倒角</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{
                  active: data.moreSet.goodsBorderAngle === 'rightAngle',
                }"
                @click="setCardBorder('rightAngle')"
              >
                直角
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{
                  active: data.moreSet.goodsBorderAngle === 'filletedCorner',
                }"
                @click="setCardBorder('filletedCorner')"
              >
                圆角
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">图片比例</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{
                  active: data.moreSet.goodsRatio === '3:2',
                  noDrop: data.curListStyleIndex == 0,
                }"
                @click="moreSetAreaClick('goodsRatio', '3:2')"
              >
                3:2
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{
                  active: data.moreSet.goodsRatio === '1:1',
                  noDrop: data.curListStyleIndex == 0,
                }"
                @click="moreSetAreaClick('goodsRatio', '1:1')"
              >
                1:1
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{
                  active: data.moreSet.goodsRatio === '3:4',
                  noDrop: data.curListStyleIndex == 0,
                }"
                @click="moreSetAreaClick('goodsRatio', '3:4')"
              >
                3:4
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{
                  active: data.moreSet.goodsRatio === '16:9',
                  noDrop: data.curListStyleIndex == 0,
                }"
                @click="moreSetAreaClick('goodsRatio', '16:9')"
              >
                16:9
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">图片填充</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.isPicFill }"
                @click="moreSetAreaClick('isPicFill', true)"
              >
                填充
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: !data.moreSet.isPicFill }"
                @click="moreSetAreaClick('isPicFill', false)"
              >
                周边留白
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">文本样式</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.textStyle === '常规' }"
                @click="moreSetAreaClick('textStyle', '常规')"
              >
                常规
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.textStyle === '加粗' }"
                @click="moreSetAreaClick('textStyle', '加粗')"
              >
                加粗
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">文本对齐</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.textAlignment === 'left' }"
                @click="moreSetAreaClick('textAlignment', 'left')"
              >
                左对齐
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.textAlignment === 'center' }"
                @click="moreSetAreaClick('textAlignment', 'center')"
              >
                居中对齐
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">页面边距</div>
          <div class="control-group__content">
            <div class="button-group">
              <el-slider
                v-model="data.moreSet.pageBackGauge"
                show-input
                :min="0"
                :max="30"
              >
              </el-slider>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">商品间距</div>
          <div class="control-group__content">
            <div class="button-group">
              <div class="block">
                <el-slider
                  v-model="data.moreSet.goodsBackGauge"
                  show-input
                  :min="0"
                  :max="30"
                >
                </el-slider>
              </div>
            </div>
          </div>
        </div>
        <div class="control-group x-bc">
          <div class="control-group__header">商品角标</div>
          <div class="control-group__content">
            <div class="button-group x-x">
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.goodsCornerMark === 0 }"
                @click="moreSetAreaClick('goodsCornerMark', 0)"
              >
                不显示
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.goodsCornerMark === 1 }"
                @click="moreSetAreaClick('goodsCornerMark', 1)"
              >
                营销角标
              </div>
              <div
                class="button-item simpleBtn cr"
                :class="{ active: data.moreSet.goodsCornerMark === 2 }"
                @click="moreSetAreaClick('goodsCornerMark', 2)"
              >
                属性角标
                <el-tooltip placement="top">
                  <div slot="content">需在商品编辑页关联标签才会显示属性角标</div>
                  <i class="el-icon-info" />
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!-- 角标样式框 -->
        <div class="control-group x-bc" v-show="data.moreSet.goodsCornerMark === 1">
          <div class="control-group__header">角标样式</div>
          <div class="control-group__content">
            <div class="shoppingCart x-bc cr" @click="selectCornerMark">
              <div class="left x-f">
                <iconpark-icon
                  :name="cornerMark[data.moreSet.curCornerMarkIndex].icon"
                  class="shoppingIcon"
                  :color="cornerMark[data.moreSet.curCornerMarkIndex].color || '#fe5a34'"
                  size="24px"
                ></iconpark-icon>
                <div class="text">
                  {{ cornerMark[data.moreSet.curCornerMarkIndex].text }}
                </div>
              </div>
              <iconpark-icon name="down"></iconpark-icon>
            </div>
          </div>
        </div>
        <!-- 选择角标区域 -->
        <div class="select-cornerMark" v-show="data.moreSet.selectCornerMark">
          <div
            class="cornerMarkItem"
            v-for="(item, index) in cornerMark"
            :key="item.id"
            @click="pitchCornerMark(index)"
          >
            <iconpark-icon
              class="cornerMarkIcon"
              :name="item.icon"
              :color="item.color || '#fe5a34'"
              size="24px"
            ></iconpark-icon>
            {{ item.text }}
          </div>
        </div>
        <!-- 自定义角标 -->
        <div class="diyCornerMark" v-show="data.moreSet.curCornerMarkIndex === 4">
          <!-- 上传角标图片 -->
          <div class="imgBox marR10" @click="materialOpen = true">
            <div class="y-c" v-if="!data.moreSet.cornerMarkUrl">
              <i class="el-icon-plus"></i>
              <span>添加图片</span>
            </div>

            <el-image
              v-else
              :src="data.moreSet.cornerMarkUrl"
              width="100px"
              height="100px"
              alt="#"
            >
            </el-image>
            <div class="tag">更换图片</div>
          </div>
          <div class="tag" v-if="!data.moreSet.cornerMarkUrl">请上传角标图片</div>
          <div>推荐使用100X100像素的.png图片</div>
        </div>
      </div>
      <div class="moreSet more" @click="clickMoreSet">
        更多设置
        <iconpark-icon :name="moreSetIcon"></iconpark-icon>
      </div>
    </el-form>
    <!-- 用户导入图片对话框 -->
    <el-dialog
      title="素材库"
      :visible.sync="materialOpen"
      width="80%"
      :destroy-on-close="true"
    >
      <Material :isDialog="true" @accessUrlListCom="geCornerMarktUrl" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 拼团商品弹框 -->
    <activity-dialog
      :open.sync="activityDia"
      :type="'1'"
      @close="closeActivityDia"
    ></activity-dialog>
  </div>
</template>

<script>
import ActivityDialog from "@/views/O2OMall/config/template/decoratePage/components/rightTemplate/components/activityDialog.vue";
import Material from "@/views/components/material/index.vue"; //素材库
export default {
  name: "RightGoods",
  components: { ActivityDialog, Material },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      activityDia: false, // 打开活动商品弹框
      goodsList: [], // 拼团商品列表
      // 拼团类型list表
      groupingTypeList: [
        { id: 1, text: "普通拼团" },
        { id: 2, text: "老带新拼团" },
        { id: 3, text: "阶梯拼团" },
        { id: 4, text: "抽奖拼团" },
      ],
      //列表样式数组
      listStyle: [
        { id: 1, icon: "yihangpailie", text: "一行一个" },
        { id: 2, icon: "lianghangpailie", text: "一行两个" },
        { id: 3, icon: "sanhangpailie", text: "一行三个" },
        { id: 4, icon: "datuxianshi", text: "大图模式" },
        { id: 5, icon: "hengxianggundong", text: "横向滑动" },
        { id: 6, icon: "yidaliangxiao", text: "一大两小" },
      ],
      //商品样式
      goodsStyle: ["无边白底", "卡片投影", "描边白底", "无边透明底", "促销", "瀑布流"],
      //购物车样式数组
      shoppingCart: [
        { id: 1, icon: "gouwuche1", text: "购物车1" },
        { id: 2, icon: "gouwuche2", text: "购物车2" },
        { id: 3, icon: "add-one-8p45gln2", text: "空心加购" },
        { id: 4, icon: "add-one", text: "实心加购" },
        { id: 5, icon: "anniu2", text: "圆角实心" },
        { id: 6, icon: "anniu3", text: "直角实心" },
        { id: 7, icon: "anniu1", text: "圆角空心" },
        { id: 8, icon: "anniu4", text: "直角空心" },
        { id: 9, icon: "buxianshi", color: "#b5b5b5", text: "不显示" },
      ],
      //角标样式数组
      cornerMark: [
        { id: 1, icon: "xinpin1", text: "新品" },
        { id: 2, icon: "remai-900m662m", text: "热卖" },
        { id: 3, icon: "new", text: "NEW" },
        { id: 4, icon: "hot-900m662n", text: "HOT" },
        { id: 9, icon: "zidingyi", color: "#b5b5b5", text: "自定义" },
      ],
      //素材库弹窗开关
      materialOpen: false,
      //上传的图片url数组
      imgUrlList: undefined,
      //更多设置区域开关
      moreSetArea: false,
      //更多设置图标
      moreSetIcon: "down",
      //是否显示自定义购物车按钮文字
      isDiyBtnText: false,
      //商品图片宽高
      photoWidthHeight: {
        height: "",
        width: "100%",
      },
      //商品图片宽高(一大两小(混合列表使用))
      photoWidthHeightTwo: {
        height: "",
        width: "100%",
      },
    };
  },
  watch: {
    //侦听购物车样式 是否显示自定位购物车按钮文字
    "data.curShoppingCardIndex": {
      handler(val) {
        this.isDiyBtnText =
          val === 4 || val === 5 || val === 6 || val === 7 ? true : false;
      },
      immediate: true,
    },
  },
  methods: {
    //传送控制中间商品样式数据
    templateMsg() {
      this.$emit("setTemp", this.data);
    },
    //点击列表样式事件
    clickListStyle(index) {
      this.data.moreSet.goodsRatio = "1:1";
      this.data.curListStyleIndex = index;

      // 还原样式
      this.data.moreSet.goodsBorderAngle = "rightAngle";
      this.data.goodsBorderAngle.borBR = 0;
      this.data.goodsBorderAngle.borBL = 0;
      this.data.goodsBorderAngle.borTL = 0;
      this.data.goodsBorderAngle.borTR = 0;

      // if (index !== 1) {
      //     this.goodsStyle = ['无边白底', '卡片投影', '描边白底', '无边透明底']
      // } else {
      //     this.goodsStyle = [
      //         '无边白底',
      //         '卡片投影',
      //         '描边白底',
      //         '无边透明底',
      //         // '促销',
      //         // '瀑布流'
      //     ]
      // }
      //商品图片宽高
      if (index === 0) this.data.photoWidthHeight.height = "188px";
      if (index === 1) this.data.photoWidthHeight.height = "188px";
      if (index === 2) this.data.photoWidthHeight.height = "125px";
      if (index === 3) this.data.photoWidthHeight.height = "250px";
      if (index === 4) this.data.photoWidthHeight.height = "113px";
      if (index === 5) {
        this.data.photoWidthHeight.height = "375px";
        this.data.photoWidthHeightTwo.height = "188px";
      }
      console.log(this.data.photoWidthHeight);
      this.templateMsg();
    },
    //点击商品样式事件
    clickGoodsStyle(index) {
      this.data.curGoodsStyleIndex = index;
      this.templateMsg();
    },
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    moreSetAreaClick(type, pitchVal) {
      if (type == "goodsRatio" && this.data.curListStyleIndex == 0) {
        return;
      }
      this.data.moreSet[type] = pitchVal;
      this.templateMsg();
    },
    // 设置圆角
    setCardBorder(type) {
      this.data.moreSet.goodsBorderAngle = type;
      if (type == "filletedCorner") {
        console.log("圆角------------------");
        if (this.data.curListStyleIndex == 0) {
          this.data.goodsBorderAngle.borTL = 10;
          this.data.goodsBorderAngle.borBL = 10;
        } else {
          this.data.goodsBorderAngle.borTL = 10;
          this.data.goodsBorderAngle.borTR = 10;
        }
      } else {
        this.data.goodsBorderAngle.borTL = 0;
        this.data.goodsBorderAngle.borTR = 0;
        this.data.goodsBorderAngle.borBL = 0;
        this.data.goodsBorderAngle.borBR = 0;
      }
    },
    //点击更多设置事件
    clickMoreSet() {
      this.moreSetArea = !this.moreSetArea;
      this.moreSetIcon = this.moreSetIcon === "down" ? "up" : "down";
    },
    //选择购物车框开关
    selectShoppingCart() {
      this.data.selectShoppingCart = !this.data.selectShoppingCart;
    },
    //选中购物车样式
    pitchShoppingCart(index) {
      this.data.curShoppingCardIndex = index;
      this.selectShoppingCart();
    },
    //选择角标框开关
    selectCornerMark() {
      this.data.moreSet.selectCornerMark = !this.data.moreSet.selectCornerMark;
    },
    //选中角标样式
    pitchCornerMark(index) {
      this.data.moreSet.curCornerMarkIndex = index;
      this.selectCornerMark();
    },
    //上传图片弹窗确定
    imgUpdateSubmit() {
      if (this.imgUrlList.length > 1) {
        return this.$message.error("角标只能选一张图片");
      }
      this.data.moreSet.cornerMarkUrl = this.imgUrlList[0];
      this.materialOpen = false;
      this.templateMsg();
    },
    //上传角标图片筛出来的url
    geCornerMarktUrl(value) {
      this.imgUrlList = value;
    },
    // 打开商品
    openDialog() {
      this.goodsList = [];
      this.activityDia = true;
    },
    // 关闭商品弹窗
    closeActivityDia(data) {
      console.log("1212:", data);
      if (data) {
        if (this.data.curListStyleIndex == 5) {
          this.data.singleGood = data[0];
          this.data.goodList[0] = data[1];
          this.data.goodList[1] = data[2];
        } else {
          this.data.goodList = data;
        }
      }
      this.activityDia = false;
      console.log("选择的商品：", this.data.goodList);
    },
  },
};
</script>

<style lang="scss" scoped>
//标题样式
.module-title {
  font-weight: 500;
  font-size: 16px;
  padding: 10px 0;
  color: #333;
}

//选择商品按钮样式
.select-btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: rgba(21, 91, 212, 0.03);
  border: 1px dashed rgba(21, 91, 212, 0.3);
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
  color: #155bd4;
  border-collapse: collapse;

  .select-num {
    color: #999;
    font-size: 12px;
  }
}

//分割线样式
.el-divider {
  margin: 10px 0;
}

//每一组控制
.control-group {
  line-height: 2;
  margin-bottom: 15px;

  .control-group__header {
    width: 100px;
    font-size: 14px;
  }
}

//列表样式
.list-radio {
  margin-top: 10px;

  .item-radio {
    text-align: center;

    .icon {
      width: 56px;
      height: 56px;
      padding: 17px;
      border: 1px solid #e0e0e0;
      margin-bottom: 8px;
    }

    .text {
      color: #999;
    }

    //焦点
    &.active {
      color: #155bd4;
    }

    &.active .icon {
      background: #edf4ff;
      border: 1px solid #155bd4;
    }

    &.active .text {
      color: #155bd4;
    }
  }
}

//商品样式
.list-goods {
  margin-top: 10px;

  .item-goods {
    text-align: center;
    width: 24%;
    margin-right: 2px;
    border: 1px solid #dcdee0;
    height: 32px;
    line-height: 30px;

    &.active {
      background: #edf4ff;
      border: 1px solid #155bd4;
      color: #155bd4;
    }
  }
}

//购物车样式
.shoppingCart {
  width: 160px;
  height: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 4px 15px 4px 6px;
  box-sizing: border-box;

  .shoppingIcon {
    margin-right: 8px;
  }
}

//选择购物车区域/选择角标区域
.select-shoppingCart,
.select-cornerMark {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
  padding: 5px;
  margin-bottom: 10px;

  .shoppingCartItem,
  .cornerMarkItem {
    display: flex;
    align-items: center;
    margin: 5px;
    width: calc(33.33% - 10px);
    border: 1px solid #e0e0e0;
    padding: 5px;
    font-size: 14px;

    .shoppingCartIcon,
    .cornerMarkIcon {
      padding: 0 5px;
    }

    &:hover {
      cursor: pointer;
      background: #e6efff;
      border: 1px solid #155bd4;
    }
  }
}

//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;

  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}

//自定义角标区域
.diyCornerMark {
  width: 100%;
  height: 140px;
  background-color: #f7f8fa;
  padding: 10px;

  .tag {
    color: #d40203;
    line-height: 20px;
  }
}

//上传图片样式
.imgBox {
  width: 70px;
  height: 70px;
  text-align: center;
  border: 1px solid #e5e5e5;
  color: #155bd4;
  font-size: 12px;
  background: #f2f4f6;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  //加号
  .el-icon-plus {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  //提示可换图片
  .tag {
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 20px;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

//滑块条
.el-slider {
  width: 280px;
}

//更多设置
.moreSet {
  text-align: center;
}

//鼠标手势
.cr {
  cursor: pointer;
}
</style>
