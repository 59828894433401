<template>
  <div style="width: 100%">
    <!-- 按钮类型 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">按钮类型:</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.btnType === 'icon' }"
          @click="getShowWithout('btnType', 'icon')"
        >
          图标
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{
            active: data.btnType === 'img'
          }"
          @click="getShowWithout('btnType', 'img')"
        >
          图片
        </div>
      </div>
    </div>
    <!-- 每行数量 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">每行数量:</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.lineNum === 3 }"
          @click="getShowWithout('lineNum', 3)"
        >
          3个
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.lineNum === 4 }"
          @click="getShowWithout('lineNum', 4)"
        >
          4个
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.lineNum === 5 }"
          @click="getShowWithout('lineNum', 5)"
        >
          5个
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div
      @mouseover="handleMouseOver(index)"
      class="boxDetail marB10"
      v-for="(item, index) in data.contentList"
      :key="index"
    >
      <!-- 按钮图标 -->
      <div class="x-x marB20">
        <span class="fontS14c3 marR20"
          >{{ data.btnType === 'icon' ? '按钮图标' : '按钮图片' }}:</span
        >
        <div class="selectIconBox x-fc marL10" @click="getReplace(index)">
          <i
            v-show="data.btnType === 'icon' ? !item.icon : !item.imgUrl"
            class="el-icon-plus"
            style="font-size: 32px"
          ></i>
          <iconParkALi
            v-show="item.icon && data.btnType === 'icon'"
            :name="item.icon"
            size="32px"
          />
          <el-image
            v-show="item.imgUrl && data.btnType === 'img'"
            style="width: 70px; height: 70px"
            :src="item.imgUrl"
          />
          <span
            v-show="data.btnType === 'icon' ? item.icon : item.imgUrl"
            class="replace"
            >替换</span
          >
        </div>
      </div>
      <!-- 按钮文字 -->
      <div class="x-f marB20">
        <span class="fontS14c3 marR20">按钮文字:</span>
        <el-input
          v-model="item.title"
          size="mini"
          class="inputWidth"
          type="text"
          maxlength="5"
          show-word-limit
        />
      </div>

      <!-- 跳转链接 -->
      <div class="marB20 x-f link">
        <span class="fontS14c3 marR20">跳转链接:</span>
        <el-input
          class="inputWidth"
          size="mini"
          v-model="item.link.name"
          readonly
        >
        </el-input>
        <span class="marL20 fontS14B cursorP" @click="getOpenLink(index)">{{
          item.link.name ? '修改' : '选择链接'
        }}</span>
      </div>

      <!-- 删除 -->
      <i
        v-show="activeIndex === index && data.contentList.length > 1"
        class="el-icon-error"
        @click="handDelete(index, data.contentList)"
      />
    </div>
    <!-- 添加 -->
    <div class="x-fc marB20">
      <el-button type="primary" size="mini" style="width: 100%" @click="addNav"
        >+添加</el-button
      >
    </div>
    <!--  背景颜色 -->
    <div class="x-bc marB20">
      <div class="x-f">
        <span class="fontS14c3 marR20">背景颜色:</span>
        <span class="fontS14">{{ data.style.bgColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker v-model="data.style.bgColor" />
      </div>
    </div>
    <!--  图标颜色 -->
    <div class="x-bc marB20" v-show="data.btnType === 'icon'">
      <div class="x-f">
        <span class="fontS14c3 marR20">图标颜色:</span>
        <span class="fontS14">{{ data.style.iconColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker v-model="data.style.iconColor" />
      </div>
    </div>
    <!--  文字颜色 -->
    <div class="x-bc marB20">
      <div class="x-f">
        <span class="fontS14c3 marR20">文字颜色:</span>
        <span class="fontS14">{{ data.style.textColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker v-model="data.style.textColor" />
      </div>
    </div>
    <!-- 上边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">上边距</span>
      <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
    </div>
    <!-- 下边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">下边距</span>
      <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
    </div>
    <!-- 左边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">左边距</span>
      <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
    </div>
    <!-- 右边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">右边距</span>
      <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
    </div>
    <!-- 圆角 -->
    <div class="x-f marB20">
      <span class="fontS14 radiuDistance">圆角</span>
      <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
    </div>
    <!-- 用户导入图片对话框 -->
    <el-dialog title="素材库" :visible.sync="materialOpen" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true"/>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
    <selectIcon :OpenIcon.sync="openIcon" @selectIcon="selectIcon" />
  </div>
</template>

<script>
import Material from '@/views/components/material/index.vue' //素材库
import selectLink from '@/views/components/selectLink/index.vue' //链接库
import selectIcon from '@/views/components/selectIcon/index.vue' //图标库
export default {
  name: 'tubiaozu',
  components: { Material, selectLink, selectIcon },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      //图标库窗
      openIcon: false,
      linkIndex: -1, //当前打开的链接库是哪个数据
      openLink: false, //链接弹窗
      accessUrlList: [], //筛选出来的图片地址数组
      materialOpen: false, //素材库弹窗
      activeIndex: -1, //移入下标
      upIndex: -1 //上传icon下标
    }
  },
  methods: {
    //选择好的图标
    selectIcon (value) {
      this.data.contentList[this.upIndex].icon = value
      this.templateMsg()
    },
    //链接赋值
    selectLinkObj (value) {
      this.data.contentList[this.linkIndex].link = value
      this.templateMsg()
    },
    //打开链接库
    getOpenLink (index) {
      this.openLink = true
      this.linkIndex = index
    },
    //上传图标或图片
    getReplace (index) {
      this.upIndex = index
      const { btnType } = this.data
      switch (btnType) {
        case 'img':
          //打开素材库
          this.materialOpen = true
          break
        case 'icon':
          //打开图标库
          this.openIcon = true
          break
        default:
          // default case, do nothing
          break
      }
    },
    //上传图片弹窗取消
    imgUpdateCancel () {
      this.accessUrlList = []
      this.materialOpen = false
    },
    //上传图片弹窗确定
    imgUpdateSubmit () {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.data.contentList[this.upIndex].imgUrl = this.accessUrlList[0]
      this.accessUrlList = []
      this.materialOpen = false
      this.templateMsg()
    },
    //图片筛出来的url
    getAccessUrlList (value) {
      this.accessUrlList = value
    },
    //删除
    handDelete (index, rows) {
      rows.splice(index, 1)
    },
    //鼠标移入
    handleMouseOver (index) {
      this.activeIndex = index
    },
    //添加导航
    addNav () {
      this.data.contentList.push({
        title: '自定义', //标题
        link: { url: '', name: '' }, //跳转链接
        icon: undefined, //图标
        imgUrl: undefined //图片
      })
      this.templateMsg()
    },
    //外部是或否
    getShowWithout (type, bol) {
      this.data[type] = bol
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
//选择图标
.selectIconBox {
  width: 70px;
  height: 70px;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f4f4f4;
  position: relative;
  //替换
  .replace {
    position: absolute;
    height: 20px;
    left: 1px;
    right: 1px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    line-height: 20px;
    margin: 0;
    text-align: center;
    display: none;
  }
}
//替换
.selectIconBox:hover .replace {
  display: inline-block;
}
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//边框详情样式
.boxDetail {
  min-width: 250px;
  padding: 15px;
  background-color: #f8f9ff;
  position: relative;
  .el-icon-error {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    color: #aaaaaa;
    cursor: pointer;
  }
  .link {
    ::v-deep .el-input--mini .el-input__inner {
      cursor: default;
    }
  }
}
//拖动
.slider {
  width: calc(100% - 106px);
}
//圆角
.radiuDistance {
  margin-right: 62px;
}
</style>
