<template>
  <div class="sousuo">
    <el-form :model="data">
      <el-form-item>
        <div class="x-bc">
          <!-- <div class="x-f">
            <span class="marR20 label">框体形状</span>
            <span>{{ data.style.frameworkStyle === 0 ? "方形" : "圆形" }}</span>
          </div> -->
          <!-- <div class="x-f btnListBox">
            <div class="item x-c" v-for="(item, index) in fromworkList" :key="index"
              @click="setFromwork(item.style, index)" :class="{ borderColor: data.style.frameworkStyle == item.style }">
              <iconpark-icon :name="item.name" size="16px"></iconpark-icon>
            </div>
          </div> -->
        </div>
      </el-form-item>
      <el-form-item>
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 label">显示位置</span>
            <span>{{ data.style.textPoint == "x-c" ? "居中显示" : "左对齐" }}</span>
          </div>
          <div class="x-f btnListBox">
            <div class="item x-c" v-for="(item, index) in locationList" :key="index"
              @click="setLocation(item.style, index)" :class="{ borderColor: data.style.textPoint == item.style }">
              <iconpark-icon :name="item.name" size="16px"></iconpark-icon>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 label">显示模式</span>
          </div>
          <div class="x-f btnListBox">
            <el-radio v-model="data.style.isFixed" :label="false">按顺序随页面滑动</el-radio>
            <el-radio v-model="data.style.isFixed" :label="true">吸顶</el-radio>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="框体高度">
        <div class="x-f">
          <el-slider style="width: 320px" :min="30" v-model="data.style.frameworkHeigth" show-input></el-slider>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 label">背景颜色</span>
            <span>{{ data.style.bgColor }}</span>
          </div>
          <div class="x-f btnListBox">
            <el-color-picker v-model="data.style.bgColor" @change="selectBGcolor"></el-color-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 label">框体颜色</span>
            <span>{{ data.style.framworkColor }}</span>
          </div>
          <div class="x-f btnListBox">
            <el-color-picker v-model="data.style.framworkColor" @change="selectFWcolor"></el-color-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 label">文本颜色</span>
            <span>{{ data.style.textColor }}</span>
          </div>
          <div class="x-f btnListBox">
            <el-color-picker v-model="data.style.textColor" @change="selectTEXTcolor"
              :predefine="SYS_COLORS"></el-color-picker>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div>
      <!-- 上边距 -->
      <div class="x-f marB20">
        <span class="fontS14 marR50">上边距</span>
        <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
      </div>
      <!-- 下边距 -->
      <div class="x-f marB20">
        <span class="fontS14 marR50">下边距</span>
        <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
      </div>
      <!-- 左边距 -->
      <div class="x-f marB20">
        <span class="fontS14 marR50">左边距</span>
        <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
      </div>
      <!-- 右边距 -->
      <div class="x-f marB20">
        <span class="fontS14 marR50">右边距</span>
        <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
      </div>
      <!-- 圆角 -->
      <div class="x-f marB20">
        <span class="fontS14 radiuDistance">圆角</span>
        <el-slider class="slider" v-model="data.style.frameworkStyle" :min="0" :max="50" />
      </div>
    </div>
  </div>
</template>

<script>
import { SYS_COLORS } from "@/utils/constant-o2o.js";
export default {
  name: "sousuo",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      SYS_COLORS,
      fromworkList: [
        { name: "zhijiaosousuo", style: 0 },
        { name: "yuanjiaosousuo", style: 50 },
      ],
      locationList: [
        {
          name: "align-text-left",
          style: "x-f",
        },
        {
          name: "align-text-center",
          style: "x-c",
        },
      ],
    };
  },
  methods: {
    templateMsg() {
      this.$emit("setTemp", this.data);
    },
    // 对齐方式设置
    setLocation(style, index) {
      this.data.style.textPoint = style;
      this.templateMsg();
    },
    // 框体形状
    setFromwork(style, index) {
      this.data.style.frameworkStyle = style;
      this.templateMsg();
    },
    // 选择背景颜色
    selectBGcolor(value) {
      this.data.style.bgColor = value;
      this.templateMsg();
    },
    // 框体颜色
    selectFWcolor(value) {
      this.data.style.framworkColor = value;
      this.templateMsg();
    },
    // 字体颜色
    selectTEXTcolor(value) {
      this.data.style.textColor = value;
      this.templateMsg();
    },
  },
};
</script>

<style lang="scss" scoped>
.sousuo {
  .lable {
    margin-right: 16px;
    font-size: 14px;
    color: #d3d3d3;
    line-height: 18px;
    white-space: nowrap;
  }

  .btnListBox {
    border-radius: 5px;

    .item {
      width: 38px;
      height: 30px;
      border: 1px solid #ebedf0;
      padding: 0 20px;
      cursor: pointer;
    }

    .borderColor {
      border: 1px solid #1c75f4;
    }
  }
}
.slider {
    width: calc(100% - 106px);
}
.radiuDistance {
    margin-right: 62px;
}
</style>
