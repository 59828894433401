<template>
  <div style="width: 100%">
    <!-- 商品单位 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">商品单位</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.isUnit }"
          @click="getShow('isUnit', true)"
        >
          显示
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !data.isUnit }"
          @click="getShow('isUnit', false)"
        >
          不显示
        </div>
      </div>
    </div>
    <!-- 营销信息 -->
    <div class="marB20">
      <div class="marB20 x-bc">
        <div class="fontS14c3">营销信息</div>
        <div class="x-x">
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.isMarketing }"
            @click="getShow('isMarketing', true)"
          >
            显示
          </div>
          <div
            class="simpleBtn cursorP"
            :class="{ active: !data.isMarketing }"
            @click="getShow('isMarketing', false)"
          >
            不显示
          </div>
        </div>
      </div>
      <div v-show="data.isMarketing" class="boxDetail">
        <div class="x-bc marB10">
          <div class="x-f">
            <span class="fontS14 marR20">字体颜色</span>
            <span class="fontS14">{{ data.marketingStyle.textColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker
              v-model="data.marketingStyle.textColor"
              @change="
                selectColorChange(
                  'data.marketingStyle.textColor',
                  data.marketingStyle.textColor
                )
              "
            ></el-color-picker>
          </div>
        </div>
        <div class="x-bc">
          <div class="x-f">
            <span class="fontS14 marR20">背景颜色</span>
            <span class="fontS14">{{ data.marketingStyle.bgColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker
              v-model="data.marketingStyle.bgColor"
              @change="
                selectColorChange(
                  'data.marketingStyle.bgColor',
                  data.marketingStyle.bgColor
                )
              "
            ></el-color-picker>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务 -->
    <div class="marB20">
      <div class="marB20 x-bc">
        <div class="fontS14c3">服务</div>
        <div class="x-x">
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.isService }"
            @click="getShow('isService', true)"
          >
            显示
          </div>
          <div
            class="simpleBtn cursorP"
            :class="{ active: !data.isService }"
            @click="getShow('isService', false)"
          >
            不显示
          </div>
        </div>
      </div>
      <div v-show="data.isService" class="boxDetail">
        <div class="x-bc marB10">
          <span class="fontS14 marR20">选择样式</span>
          <el-radio-group v-model="data.serviceStyle.shape" size="mini">
            <el-radio-button label="duihao">
              <iconpark-icon color="#333333" name="check-one" />
            </el-radio-button>
            <el-radio-button label="dian">
              <iconpark-icon color="#333333" name="dot" />
            </el-radio-button>
          </el-radio-group>
        </div>
        <div class="x-bc marB10">
          <div>
            <span class="fontS14 marR20">标签颜色</span>
            <span class="fontS14">{{ data.serviceStyle.labelColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker
              v-model="data.serviceStyle.labelColor"
              @change="
                selectColorChange(
                  'data.serviceStyle.labelColor',
                  data.serviceStyle.labelColor
                )
              "
            ></el-color-picker>
          </div>
        </div>
        <div class="x-bc marB10">
          <div>
            <span class="fontS14 marR20">字体颜色</span>
            <span class="fontS14">{{ data.serviceStyle.textColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker
              v-model="data.serviceStyle.textColor"
              @change="
                selectColorChange(
                  'data.serviceStyle.textColor',
                  data.serviceStyle.textColor
                )
              "
            ></el-color-picker>
          </div>
        </div>
        <div class="x-bc">
          <div>
            <span class="fontS14 marR20">背景颜色</span>
            <span class="fontS14">{{ data.serviceStyle.bgColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker
              v-model="data.serviceStyle.bgColor"
              @change="
                selectColorChange(
                  'data.serviceStyle.bgColor',
                  data.serviceStyle.bgColor
                )
              "
            ></el-color-picker>
          </div>
        </div>
      </div>
    </div>
    <!-- 标题颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">标题颜色</span>
        <span class="fontS14">{{ data.contentStyle.titleColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.titleColor"
          @change="
            selectColorChange(
              'data.contentStyle.titleColor',
              data.contentStyle.titleColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 副标题颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">介绍颜色</span>
        <span class="fontS14">{{ data.contentStyle.subtitleColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.subtitleColor"
          @change="
            selectColorChange(
              'data.contentStyle.subtitleColor',
              data.contentStyle.subtitleColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 价格颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">价格颜色</span>
        <span class="fontS14">{{ data.contentStyle.priceColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.priceColor"
          @change="
            selectColorChange(
              'data.contentStyle.priceColor',
              data.contentStyle.priceColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 销量颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">销量颜色</span>
        <span class="fontS14">{{ data.contentStyle.salesVolumeColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.salesVolumeColor"
          @change="
            selectColorChange(
              'data.contentStyle.salesVolumeColor',
              data.contentStyle.salesVolumeColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 背景颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">背景颜色</span>
        <span class="fontS14">{{ data.contentStyle.bgColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.contentStyle.bgColor"
          @change="
            selectColorChange(
              'data.contentStyle.bgColor',
              data.contentStyle.bgColor
            )
          "
        ></el-color-picker>
      </div>
    </div>
    <!-- 上边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">上边距</span>
      <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
    </div>
    <!-- 下边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">下边距</span>
      <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
    </div>
    <!-- 左边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">左边距</span>
      <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
    </div>
    <!-- 右边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">右边距</span>
      <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
    </div>
    <!-- 圆角 -->
    <div class="x-f marB20">
      <span class="fontS14 radiuDistance">圆角</span>
      <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpinxinxi',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  computed: {
    marLRHalf: function () {
      return this.data.marLR / 2
    }
  },
  data () {
    return {}
  },
  methods: {
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    getShow (type, bol) {
      this.data[type] = bol
      this.templateMsg()
    },

    templateMsg () {
      this.$emit('setTemp', this.data)
    },
    // 颜色设置
    selectColorChange (attribute, value) {
      this[attribute] = value
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//边框详情样式
.boxDetail {
  margin-left: 110px;
  min-height: 100px;
  width: 250px;
  padding: 15px;
  background-color: #f8f9ff;
}
//拖动
.slider {
  width: calc(100% - 106px);
}
//圆角
.radiuDistance {
  margin-right: 62px;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
