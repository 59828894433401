var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data, rules: _vm.rules } },
        [
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("应用页面"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "x-bc" }, [
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.data.checkedList,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "checkedList", $$v)
                        },
                        expression: "data.checkedList",
                      },
                    },
                    _vm._l(_vm.data.tableList, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item, attrs: { label: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "imageNavText" },
              [
                _vm._l(_vm.data.immageNavList, function (item, index) {
                  return _c(
                    "el-card",
                    { key: index, staticClass: "product-item marB10" },
                    [
                      _c("div", { staticClass: "x-bc" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.data.textFormworkNav == "imageFormwork",
                                expression:
                                  "data.textFormworkNav == 'imageFormwork'",
                              },
                            ],
                            staticClass: "imgBox marR10",
                            on: {
                              click: function ($event) {
                                return _vm.getImgLibrary(index)
                              },
                            },
                          },
                          [
                            !item.imgUrl
                              ? _c("div", { staticClass: "y-c" }, [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("span", [_vm._v("添加图片")]),
                                ])
                              : _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: item.imgUrl, alt: "#" },
                                }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "imgBoxTitle" }, [
                          _c(
                            "div",
                            { staticClass: "marB15 x-x" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("标题"),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  maxlength: "4",
                                  placeholder: "导航标题最多四个字",
                                },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "x-x" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("链接"),
                              ]),
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  placeholder:
                                    item.path == "" ? "选择链接" : "修改",
                                  readonly: "",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleChange(index)
                                  },
                                },
                                model: {
                                  value: item.link,
                                  callback: function ($$v) {
                                    _vm.$set(item, "link", $$v)
                                  },
                                  expression: "item.link",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "product-actions" }, [
                        _c("i", {
                          staticClass: "el-icon-error",
                          on: {
                            click: function ($event) {
                              return _vm.deleteProduct(index)
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.immageNavList.length < 6,
                        expression: "data.immageNavList.length < 6",
                      },
                    ],
                    staticClass: "addImageNav",
                    on: { click: _vm.getAddImageNav },
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus marL10" }),
                    _c("span", { staticClass: "addImageNavText" }, [
                      _vm._v("添加图文导航"),
                    ]),
                  ]
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材库",
            visible: _vm.materialOpen,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.materialOpen = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }