var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "x-bc marB20" },
      [
        _c("span", { staticClass: "fontS14" }, [_vm._v("样式")]),
        _c(
          "el-radio-group",
          {
            attrs: { size: "mini" },
            model: {
              value: _vm.data.shape,
              callback: function ($$v) {
                _vm.$set(_vm.data, "shape", $$v)
              },
              expression: "data.shape",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "yuanjiao" } }, [
              _vm._v("圆角"),
            ]),
            _c("el-radio-button", { attrs: { label: "yuanxing" } }, [
              _vm._v("圆形"),
            ]),
            _c("el-radio-button", { attrs: { label: "shuzi" } }, [
              _vm._v("数字"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "x-bc marB20" }, [
      _c("div", { staticClass: "x-f" }, [
        _c("span", { staticClass: "marR20 label" }, [_vm._v("显示位置")]),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.data.position === "left"
                ? "左对齐"
                : _vm.data.position === "center"
                ? "居中显示"
                : _vm.data.position === "right"
                ? "右对齐"
                : "未知对齐方式"
            )
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f btnListBox" },
        _vm._l(_vm.locationList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item x-c",
              class: { borderColor: _vm.locationItem == index },
              on: {
                click: function ($event) {
                  return _vm.setLocation(item.style, index)
                },
              },
            },
            [_c("iconpark-icon", { attrs: { name: item.name, size: "16px" } })],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "x-bc" }, [
      _c("div", { staticClass: "x-f" }, [
        _c("span", { staticClass: "fontS14 marR20" }, [_vm._v("选中颜色")]),
        _c("span", { staticClass: "fontS14" }, [
          _vm._v(_vm._s(_vm.data.bgColor)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-f btnListBox" },
        [
          _c("el-color-picker", {
            on: { change: _vm.selectbgColorChange },
            model: {
              value: _vm.data.bgColor,
              callback: function ($$v) {
                _vm.$set(_vm.data, "bgColor", $$v)
              },
              expression: "data.bgColor",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }