var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sousuo" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data } },
        [
          _c("el-form-item", [_c("div", { staticClass: "x-bc" })]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("显示位置"),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.data.style.textPoint == "x-c" ? "居中显示" : "左对齐"
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                _vm._l(_vm.locationList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item x-c",
                      class: {
                        borderColor: _vm.data.style.textPoint == item.style,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setLocation(item.style, index)
                        },
                      },
                    },
                    [
                      _c("iconpark-icon", {
                        attrs: { name: item.name, size: "16px" },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("显示模式"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      model: {
                        value: _vm.data.style.isFixed,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "isFixed", $$v)
                        },
                        expression: "data.style.isFixed",
                      },
                    },
                    [_vm._v("按顺序随页面滑动")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      model: {
                        value: _vm.data.style.isFixed,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "isFixed", $$v)
                        },
                        expression: "data.style.isFixed",
                      },
                    },
                    [_vm._v("吸顶")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "框体高度" } }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("el-slider", {
                  staticStyle: { width: "320px" },
                  attrs: { min: 30, "show-input": "" },
                  model: {
                    value: _vm.data.style.frameworkHeigth,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "frameworkHeigth", $$v)
                    },
                    expression: "data.style.frameworkHeigth",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("背景颜色"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.bgColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c("el-color-picker", {
                    on: { change: _vm.selectBGcolor },
                    model: {
                      value: _vm.data.style.bgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "bgColor", $$v)
                      },
                      expression: "data.style.bgColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("框体颜色"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.framworkColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c("el-color-picker", {
                    on: { change: _vm.selectFWcolor },
                    model: {
                      value: _vm.data.style.framworkColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "framworkColor", $$v)
                      },
                      expression: "data.style.framworkColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("文本颜色"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.textColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c("el-color-picker", {
                    attrs: { predefine: _vm.SYS_COLORS },
                    on: { change: _vm.selectTEXTcolor },
                    model: {
                      value: _vm.data.style.textColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "textColor", $$v)
                      },
                      expression: "data.style.textColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("上边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 50 },
              model: {
                value: _vm.data.marT,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "marT", $$v)
                },
                expression: "data.marT",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("下边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 50 },
              model: {
                value: _vm.data.marB,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "marB", $$v)
                },
                expression: "data.marB",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("左边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 25 },
              model: {
                value: _vm.data.marL,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "marL", $$v)
                },
                expression: "data.marL",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 marR50" }, [_vm._v("右边距")]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 25 },
              model: {
                value: _vm.data.marR,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "marR", $$v)
                },
                expression: "data.marR",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "x-f marB20" },
          [
            _c("span", { staticClass: "fontS14 radiuDistance" }, [
              _vm._v("圆角"),
            ]),
            _c("el-slider", {
              staticClass: "slider",
              attrs: { min: 0, max: 50 },
              model: {
                value: _vm.data.style.frameworkStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "frameworkStyle", $$v)
                },
                expression: "data.style.frameworkStyle",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }