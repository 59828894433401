<template>
    <div>
        <el-form :model="data" :rules="rules">
            <el-form-item label="公告" prop="title">
                <el-input v-model="data.title" placeholder="请输入标题" @change="setMessage"></el-input>
            </el-form-item>
            <el-form-item>
                <div class="x-bc">
                    <div class="x-f">
                        <span class="marR20 label">字体颜色</span>
                        <span>{{data.style.textColor}}</span>
                    </div>
                    <div class="x-f btnListBox">
                        <el-color-picker v-model="data.style.textColor" @change="selectbgColorChange"></el-color-picker>
                    </div>
                </div>
            </el-form-item>
            <el-form-item>
                <div class="x-bc">
                    <div class="x-f">
                        <span class="marR20 label">背景颜色</span>
                        <span>{{data.style.bgColor}}</span>
                    </div>
                    <div class="x-f btnListBox">
                        <el-color-picker v-model="data.style.bgColor" @change="selecttextColorChange"></el-color-picker>
                    </div>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'gonggao',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data(){
        return{
            rules:{
                'data.title': [
                    { required: true, message: '请输入活动名称', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        templateMsg() {
            this.$emit('setTemp', this.data)
        },
        // 设置公告内容
        setMessage(value){
            this.data.title = value
            this.templateMsg()
        },
        // 背景颜色设置
        selectbgColorChange(value){
            this.data.style.textColor = value
            this.templateMsg()
        },
        // 文字颜色设置
        selecttextColorChange(value){
            this.data.style.bgColor = value
            this.templateMsg()
        }
    }
}
</script>

<style scoped>

</style>
